import { defaultCountryCode } from '@components/country-code/constants';
import { BloodGroups } from '@types';

import {
  IMemberFormFieldsState,
  MemberFormFields,
  MemberFormSections
} from './types';

const membersTableColumn = [
  { Header: 'Name', accessor: 'name', width: '20%' },
  { Header: 'Mobile No', accessor: 'phoneNumber', width: '18%' },
  { Header: 'Groups', accessor: 'groups', width: '24%' },
  { Header: 'Guardian Name', accessor: 'guardianName', width: '20%' },
  { Header: 'Actions', accessor: 'actions' }
];

const defaultMemberFormData: IMemberFormFieldsState = {
  [MemberFormFields.NAME]: '',
  [MemberFormFields.EMAIL]: '',
  [MemberFormFields.PRIMARY_COUNTRY_CODE]: defaultCountryCode,
  [MemberFormFields.PRIMARY_NUMBER]: '',
  [MemberFormFields.ALTERNATE_COUNTRY_CODE]: defaultCountryCode,
  [MemberFormFields.ALTERNATE_NUMBER]: '',
  [MemberFormFields.DATE_OF_BIRTH]: '',
  [MemberFormFields.BLOOD_GROUP]: '',

  [MemberFormFields.ADDRESS_LINE_1]: '',
  [MemberFormFields.ADDRESS_LINE_2]: '',
  [MemberFormFields.COUNTRY]: '',
  [MemberFormFields.CITY]: '',
  [MemberFormFields.STATE]: '',
  [MemberFormFields.PINCODE]: '',

  [MemberFormFields.GUARDIAN_NAME]: '',
  [MemberFormFields.ADMISSION_NUMBER]: '',
  [MemberFormFields.ADMISSION_DATE]: '',
  [MemberFormFields.IS_INSURED]: 'false'
};

const bloodGroupOptions = Object.entries(BloodGroups).map(
  ([bloodGroupKey, bloodGroup]) => ({
    id: bloodGroupKey,
    value: bloodGroup,
    label: bloodGroup
  })
);

const isInuredOptions = [
  { value: 'Yes', key: 'true' },
  { value: 'No', key: 'false' }
];

const memberFormSidebarItems = [
  {
    id: MemberFormSections.PERSONAL_INFO,
    label: 'Personal Information'
  },
  // {
  //   id: MemberFormSections.ADDRESS,
  //   label: 'Address'
  // },
  {
    id: MemberFormSections.MORE_INFO,
    label: 'More Information'
  }
];

const defaultUserProfileTemplateHeaders = [
  'name',
  'primaryNumberCode',
  'primaryNumber',
  'altNumberCode',
  'altNumber',
  'email',
  'dateOfBirth',
  'guardianName',
  'admissionNumber',
  'admissionDate'
];

const templateUserProfileDummayData = [
  'John',
  '91',
  'XXXXXXXXXX',
  '91',
  'XXXXXXXXXX',
  'XXXXXX@gmail.com',
  '2024-02-14',
  'Doe',
  '',
  '2024-02-14'
];

export {
  defaultMemberFormData,
  bloodGroupOptions,
  memberFormSidebarItems,
  isInuredOptions,
  membersTableColumn,
  defaultUserProfileTemplateHeaders,
  templateUserProfileDummayData
};
