import { useEffect, useId, useState } from 'react';
import { useSelector } from 'react-redux';

import { defaultCountryCode } from '@components/country-code/constants';
import { CountryData } from '@components/country-code/types';
import FeeConfigInput from '@components/fee-config-input/FeeConfigInput';
import {
  duplicatePhoneNumberErrorMessage,
  GROUP_MEMBER_MAX_AMOUNT,
  GROUP_MEMBER_MIN_AMOUNT,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH
} from '@constants/generic';
import { invalidCharInNameRegex } from '@constants/regex';
import { PaymentCollectionType } from '@features/group-management/constants';
import {
  findDueDateFromStartDate,
  formatDueDatePlaceholder
} from '@features/group-management/helper';
import { isValidPhoneNumber } from '@helpers/generic';
import useNotify from '@hooks/notify';
import {
  AddMemberModalType,
  AddMemberProps,
  FeeCategoryDataType,
  PaymentCollectionArg,
  PaymentCollectionComponentType,
  ValidationError
} from '@types';
import { formatISODate, isTodayOrFutureDate } from '@utils/date';
import {
  getAmountErrorMessage,
  getNameErrorMessage,
  isMinLengthSatisfied,
  sanitizeNumberInput
} from '@utils/generic';
import {
  isInvalidAmount,
  isInvalidEmail,
  isInvalidMemberName
} from '@utils/validations';

import AmountInput from '../amount-input/AmountInput';
import AmountInputTooltip from '../amount-input-tooltip/AmountInputTooltip';
import Button from '../button/Button';
import CountryCode from '../country-code/CountryCode';
import Input from '../input/Input';
import Label from '../label/Label';
import PaymentCollection from '../payment-collection/PaymentCollection';

import Modal from '../../layouts/modal/Modal';

const AddMemberModal = ({
  type = AddMemberModalType.ADD,
  isOpen,
  handleCancel,
  addMember,
  paymentCollectionType,
  selectedmember,
  groupAmount,
  groupDueDate,
  groupFrequency,
  isFeeCategoryEnabled = false,
  feeCategoryData,
  feeCategoriesValues,
  memberList,
  groupStartDate = ''
}: AddMemberProps) => {
  const id = useId();

  const [feeCategories, setFeeCategories] = useState<FeeCategoryDataType[]>([]);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [primaryCountryCode, setPrimaryCountryCode] =
    useState<string>(defaultCountryCode);
  const [primaryNumber, setPrimaryNumber] = useState<string>('');
  const [altCountryCode, setAltCountryCode] =
    useState<string>(defaultCountryCode);
  const [altNumber, setAltNumber] = useState<string>('');
  const [amount, setAmount] = useState<string>(groupAmount || '');
  const [dueDate, setDueDate] = useState<string>(groupDueDate || '');
  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<string>('');
  const [error, setError] = useState<ValidationError>({});

  const { orgConfig } = useSelector((state: any) => state.user);
  const { appConfig } = useSelector((state: any) => state.user);
  const { createErrorAlert } = useNotify();

  const groupMemberMaxAmount =
    orgConfig?.group_member_amount_max || GROUP_MEMBER_MAX_AMOUNT;
  const groupMemberMinAmount =
    orgConfig?.group_member_amount_min || GROUP_MEMBER_MIN_AMOUNT;
  const memberNameMaxLength =
    orgConfig?.member_name_length_max || NAME_MAX_LENGTH;
  const memberNameMinLength =
    orgConfig?.member_name_length_min || NAME_MIN_LENGTH;
  const isEmailEnabled = !!orgConfig?.is_email_feature_enabled;

  const onMemberNameChange = (value: string) => {
    setError({ ...error, name: '' });
    setName(value);
  };

  const onMemberEmailChange = (value: string) => {
    setError({ ...error, email: '' });
    setEmail(value);
  };

  const onEmailBlur = (value: string) => {
    if (value && isInvalidEmail(value)) {
      setError({ ...error, email: 'Invalid email' });
    }
  };

  const onMemberNameBlur = (value: string) => {
    if (!isMinLengthSatisfied(value))
      setError({
        ...error,
        name: getNameErrorMessage(memberNameMinLength)
      });
  };

  const onPrimaryNumberChange = (value: string) => {
    if (primaryNumber === altNumber) {
      setError({ ...error, altNum: '', primaryNum: '' });
    } else {
      setError({ ...error, primaryNum: '' });
    }
    if (value.length <= 10) {
      setPrimaryNumber(value);
    }
  };

  const getCountryId = (countryCode: string) =>
    (appConfig.supported_phone_number_countries || []).find(
      (country: CountryData) => `${country.dial_code}` === countryCode
    )?.code;

  const onPrimaryCountryCodeChange = (value: string) => {
    if (
      primaryNumber &&
      !isValidPhoneNumber(primaryNumber, getCountryId(value))
    ) {
      setError({ ...error, primaryNum: 'Invalid phone number' });
    } else {
      setError({ ...error, primaryNum: '' });
    }
    setPrimaryCountryCode(value);
  };

  const onPrimaryNumberBlur = (value: string) => {
    if (!value) return;
    let errorMessage = '';
    if (!isValidPhoneNumber(value, getCountryId(primaryCountryCode)))
      errorMessage = 'Invalid phone number';
    if (errorMessage !== '') setError({ ...error, primaryNum: errorMessage });
    if (
      value &&
      `${primaryCountryCode}${value}` === `${altCountryCode}${altNumber}`
    )
      setError({ ...error, altNum: duplicatePhoneNumberErrorMessage });
  };

  const onAltNumberChange = (value: string) => {
    if (altNumber === primaryNumber) {
      setError({ ...error, primaryNum: '', altNum: '' });
    } else {
      setError({ ...error, altNum: '' });
    }

    if (value.length <= 10) {
      setAltNumber(value);
    }
  };

  const onAltCountryCodeChange = (value: string) => {
    if (altNumber && !isValidPhoneNumber(altNumber, getCountryId(value))) {
      setError({ ...error, altNum: 'Invalid phone number' });
    } else {
      setError({ ...error, altNum: '' });
    }
    setAltCountryCode(value);
  };

  const onAltNumberBlur = (value: string) => {
    if (!value) return;
    let errorMessage = '';
    if (!isValidPhoneNumber(value, getCountryId(altCountryCode)))
      errorMessage = 'Invalid phone number';
    else if (
      value &&
      `${primaryCountryCode}${primaryNumber}` === `${altCountryCode}${value}`
    )
      errorMessage = duplicatePhoneNumberErrorMessage;
    if (errorMessage !== '') setError({ ...error, altNum: errorMessage });
  };

  const onAmountChange = (value: string) => {
    setError({ ...error, amount: '' });
    const sanitizedAmount = sanitizeNumberInput(value);
    setAmount(sanitizedAmount);
  };

  const onAmountBlur = (value: string) => {
    if (value.length && Number(value) < Number(groupMemberMinAmount)) {
      setError({
        ...error,
        amount: getAmountErrorMessage(groupMemberMinAmount)
      });
    }
  };

  const formattedDuesDate = () => {
    const dueDateValue = dueDate || groupDueDate || '';
    const formattedDate = formatISODate({
      date:
        groupStartDate && isTodayOrFutureDate(groupStartDate)
          ? findDueDateFromStartDate(
              paymentCollectionType,
              dueDateValue,
              groupStartDate
            )
          : dueDateValue,
      separator: '-',
      format: 'yyyy-mm-dd'
    });
    return formattedDate;
  };

  // Todo: update primaryNumber type Sec
  const saveCurrentMember = () => {
    addMember({
      primaryNumber,
      name,
      altNumber: altNumber || '',
      amount: amount || groupAmount || '',
      dueDate: formattedDuesDate(),
      feeCategoryData: feeCategories,
      id:
        type === AddMemberModalType.EDIT
          ? memberId
          : `member_${id}_${name}${primaryCountryCode}${primaryNumber}`,
      primaryNumberCountryCode: primaryCountryCode,
      altNumberCountryCode: altCountryCode,
      email
    });
  };

  const onSave = () => {
    const hasDuplicate = memberList.some(
      (member) =>
        `${member.name}_${member.primaryNumberCountryCode}${member.primaryNumber}` ===
          `${name}_${primaryCountryCode}${primaryNumber}` &&
        member.id !== memberId
    );

    if (hasDuplicate) {
      createErrorAlert('Member already exists');
      return;
    }

    saveCurrentMember();
    handleCancel();
  };

  const getFeeConfigValue = (categoryItem: any, index: number) => {
    const category = selectedmember?.feeCategoryData?.find(
      (item) =>
        item.id === (categoryItem.branch_fee_category_id ?? categoryItem.id)
    );
    if (category?.isManuallyUpdated) return category.value;
    return (
      (feeCategoriesValues && feeCategoriesValues[index]?.value) ||
      categoryItem.amount ||
      categoryItem.value ||
      0
    );
  };

  const isFeeConfigUpdated = (categoryItem: any) => {
    const category = selectedmember?.feeCategoryData?.find(
      (item) =>
        item.id === (categoryItem.branch_fee_category_id ?? categoryItem.id)
    );
    return !!category?.isManuallyUpdated;
  };

  const resetFeeCategory = () => {
    const formattedData = feeCategoryData?.map((item: any, index: number) => ({
      id: item.branch_fee_category_id ?? item.id,
      label: item.category || item.label,
      value: getFeeConfigValue(item, index),
      isManuallyUpdated: isFeeConfigUpdated(item)
    }));

    setFeeCategories(formattedData || []);
  };

  const getFeeCofigTotalAmount = () =>
    feeCategoryData
      ?.reduce(
        (sum, item, index) => sum + Number(getFeeConfigValue(item, index)),
        0
      )
      .toString() || '0';

  const onSaveAndAddAnother = () => {
    const hasDuplicate = memberList.some(
      (member) =>
        `${member.name}_${member.primaryNumberCountryCode}${member.primaryNumber}` ===
          `${name}_${primaryCountryCode}${primaryNumber}` &&
        member.id !== memberId
    );

    if (hasDuplicate) {
      createErrorAlert('Member already exists');
      return;
    }

    saveCurrentMember();
    setName('');
    setPrimaryNumber('');
    setAltNumber('');
    setAmount(groupAmount || '');
    setDueDate(groupDueDate || '');
    setIsCalendarVisible(false);
    setEmail('');
    setAltCountryCode(defaultCountryCode);
    setPrimaryCountryCode(defaultCountryCode);
    resetFeeCategory();
  };

  const onDueDateChange = (data: PaymentCollectionArg) => {
    setDueDate(String(data.date));
    setIsCalendarVisible(false);
  };

  const areAllFeeCategoriesFilled =
    feeCategories.length > 0 &&
    feeCategories.every(
      (category: any) => category.value !== undefined && category.value !== ''
    );

  const isSaveDisabled =
    (amount &&
      isInvalidAmount(amount, groupMemberMaxAmount, groupMemberMinAmount)) ||
    isInvalidMemberName(name, memberNameMaxLength, memberNameMinLength) ||
    !isValidPhoneNumber(primaryNumber, getCountryId(primaryCountryCode)) ||
    !!(
      altNumber && !isValidPhoneNumber(altNumber, getCountryId(altCountryCode))
    ) ||
    `${altCountryCode}_${altNumber}` ===
      `${primaryCountryCode}_${primaryNumber}` ||
    (feeCategories.length > 0 &&
      isFeeCategoryEnabled &&
      !areAllFeeCategoriesFilled) ||
    !!(email && isInvalidEmail(email));

  const hasChanges =
    selectedmember?.name !== name ||
    selectedmember?.primaryNumber !== primaryNumber ||
    selectedmember.altNumber !== altNumber ||
    selectedmember.amount !== amount ||
    selectedmember.dueDate !== dueDate ||
    selectedmember.altNumberCountryCode !== altCountryCode ||
    selectedmember.primaryNumberCountryCode !== primaryCountryCode ||
    selectedmember.email !== email;

  const getSelectedDate = () => {
    if (selectedmember?.dueDate) return new Date(selectedmember?.dueDate);
    if (dueDate) return new Date(dueDate);
    return undefined;
  };

  const onCategoryAmountChange = (index: number, value: string) => {
    const updatedCategories = [...feeCategories];
    updatedCategories[index].value = sanitizeNumberInput(value);
    updatedCategories[index].isManuallyUpdated = true;
    setFeeCategories(updatedCategories);

    const totalAmount = updatedCategories.reduce(
      (sum, category) => sum + (Number(category.value) || 0),
      0
    );
    // Update the main amount input
    setAmount(totalAmount.toString());
  };

  const formattedFeeCategories = feeCategories.map(({ label, value }) => ({
    category: label,
    amount: value
  }));

  useEffect(() => {
    if (type === AddMemberModalType.EDIT) {
      setName(selectedmember?.name || '');
      setPrimaryNumber(selectedmember?.primaryNumber || '');
      setPrimaryCountryCode(
        selectedmember?.primaryNumberCountryCode || defaultCountryCode
      );
      setAltNumber(selectedmember?.altNumber || '');
      setAltCountryCode(
        selectedmember?.altNumberCountryCode || defaultCountryCode
      );
      setAmount(
        isFeeCategoryEnabled && feeCategoryData
          ? getFeeCofigTotalAmount()
          : selectedmember?.amount || groupAmount || ''
      );
      setDueDate(selectedmember?.dueDate || groupDueDate || '');
      setMemberId(selectedmember?.id || '');
      setEmail(selectedmember?.email || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (isCalendarVisible) {
      const element = document.getElementById('due-date-calendar');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }, [isCalendarVisible]);

  useEffect(() => {
    resetFeeCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeCategoryData]);

  return (
    <Modal isOpen={isOpen} onCancel={handleCancel} isCloseIconRequired>
      <div className="relative flex h-165 min-w-125 max-w-175 flex-col justify-start gap-4 rounded-lg bg-theme py-6">
        <div className="px-6 text-xl font-medium">
          {type === AddMemberModalType.EDIT
            ? 'Edit Member Details'
            : 'Add Member Details'}
        </div>
        <div className="mt-6 flex w-full flex-col gap-4 overflow-y-auto px-6">
          <Label label="Name" className="!mb-0" isRequired />
          <Input
            value={name}
            handleInputChange={onMemberNameChange}
            inputRegex={invalidCharInNameRegex}
            type="text"
            placeholder="Name"
            maxLength={memberNameMaxLength}
            minLength={memberNameMinLength}
            error={!!error.name}
            errorMessage={error.name}
            handleInputBlur={onMemberNameBlur}
          />
          <Label
            label="Primary Number"
            className={`!mb-0 ${error.name ? 'mt-2' : ''}`}
            isRequired
          />
          <div className="flex gap-3">
            <CountryCode
              hasSelection
              countryCode={primaryCountryCode}
              setCountryCode={onPrimaryCountryCodeChange}
            />
            <Input
              className="w-full"
              value={primaryNumber}
              handleInputChange={onPrimaryNumberChange}
              placeholder="Enter phone number"
              type="phone"
              error={!!error.primaryNum}
              errorMessage={error.primaryNum}
              handleInputBlur={onPrimaryNumberBlur}
            />
          </div>
          <Label
            label="Alternate Number"
            className={`!mb-0 ${error.primaryNum ? 'mt-2' : ''}`}
          />
          <div
            className={`flex gap-3 ${
              error.altNum === duplicatePhoneNumberErrorMessage ? 'mb-4' : ''
            }`}
          >
            <CountryCode
              hasSelection
              countryCode={altCountryCode}
              setCountryCode={onAltCountryCodeChange}
            />
            <Input
              className="w-full"
              value={altNumber}
              handleInputChange={onAltNumberChange}
              placeholder="Enter phone number"
              type="phone"
              error={!!error.altNum}
              errorMessage={error.altNum}
              handleInputBlur={onAltNumberBlur}
              errorStyle={
                error.altNum === duplicatePhoneNumberErrorMessage
                  ? '!-bottom-10'
                  : ''
              }
            />
          </div>
          {isEmailEnabled && (
            <>
              <Label
                label="Email"
                className={`!mb-0 ${error.altNum ? 'mt-2' : ''}`}
              />
              <Input
                value={email}
                handleInputChange={onMemberEmailChange}
                type="text"
                placeholder="Email"
                error={!!error.email}
                errorMessage={error.email}
                handleInputBlur={onEmailBlur}
              />
            </>
          )}
          <div className={`flex  ${error.email ? 'mt-2' : ''}`}>
            <Label label="Amount" className="!mb-0 mr-2" isRequired />
            <AmountInputTooltip
              maxAmount={groupMemberMaxAmount}
              minAmount={groupMemberMinAmount}
              tooltipId="add-member-modal"
            />
          </div>
          <AmountInput
            value={amount}
            placeholder="Enter Amount"
            onAmountChange={onAmountChange}
            maxAmount={groupMemberMaxAmount}
            handleInputBlur={onAmountBlur}
            error={!!error.amount}
            errorMessage={error.amount}
            disabled={isFeeCategoryEnabled}
          />
          {isFeeCategoryEnabled && (
            <FeeConfigInput
              feeCategories={formattedFeeCategories}
              onCategoryAmountChange={({ index, value }) => {
                onCategoryAmountChange(index, value);
              }}
              memberActionType="add"
            />
          )}
          <Label
            label="Due Date"
            tooltipText="If not given, the due date of group will be taken into account"
            className={`!mb-0 ${error.amount ? 'mt-2' : ''}`}
            tooltipId="dueDate"
          />
          <Input
            leftIconName="calendar"
            value={
              dueDate
                ? formatDueDatePlaceholder(
                    paymentCollectionType || PaymentCollectionType.MONTHLY,
                    groupFrequency || 1,
                    new Date(dueDate)
                  )
                : ''
            }
            onClick={() => setIsCalendarVisible(true)}
            placeholder="Select Date"
            readOnly
            disabled={paymentCollectionType === PaymentCollectionType.ONCE}
          />
          {isCalendarVisible && (
            <div
              className="mx-10 my-6 flex justify-center rounded-lg border"
              id="due-date-calendar"
            >
              <PaymentCollection
                type={paymentCollectionType as PaymentCollectionComponentType}
                isOpen={isCalendarVisible}
                confirmButtonLabel="Confirm"
                confirmButtonStyle="text-primary"
                handleConfirm={onDueDateChange}
                cancelButtonLabel="Cancel"
                handleCancel={() => {
                  setIsCalendarVisible(false);
                }}
                selectedDate={getSelectedDate()}
                selectedtype={
                  (paymentCollectionType as PaymentCollectionType) ||
                  PaymentCollectionType.MONTHLY
                }
                selectedFrequency={groupFrequency}
              />
            </div>
          )}
        </div>
        {/* TODO: button disabled needs to be handled */}
        <div className="flex h-auto w-full gap-6 px-6">
          {type === AddMemberModalType.ADD && (
            <Button
              label="Save and add another"
              variant="secondary"
              handleClick={onSaveAndAddAnother}
              disabled={isSaveDisabled}
              className="w-full"
            />
          )}
          <Button
            label="Save"
            handleClick={onSave}
            disabled={isSaveDisabled || !hasChanges}
            className={type === AddMemberModalType.EDIT ? 'w-full' : 'w-full'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddMemberModal;
