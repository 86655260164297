export const AUTH = {
  LOGIN: '/login',
  SELECT_ACCOUNT: '/select-account',
  SELECT_PAYMENT: '/select-payment',
  DOCUMENT_UPLOAD: '/document-upload',
  CREATE_BRANCH: '/create-branch',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  ACCEPT_INVITE: '/accept-invite',
  ORG_SELECTION: '/organization-selection'
};

export const HOME = '/';

export const GROUPS = {
  HOME: '/groups',
  ADD_NEW_GROUP: '/groups/add',
  GROUP_INFO: '/groups/:groupId',
  ADD_MEMBER: '/groups/:groupId/add-members',
  EDIT_GROUP: '/groups/:groupId/edit'
};

export const BRANCHES = {
  BRANCHES: '/branches',
  ADD_NEW_BRANCH: '/branches/add',
  EDIT_BRANCH: '/branches/edit/:branchId'
};

export const TEAM_MANAGEMENT = {
  TEAM_MANAGEMENT: '/team-management',
  INVITE_MEMBER: '/team-management/invite',
  EDIT_ROLE: '/team-management/edit-role/:userId'
};

export const ADMIN = {
  KYC_DOCUMENTS: '/kyc-documents',
  ORGANIZATION_DETAILS: '/kyc-documents/organization/:orgId',
  INDIVIDUAL_DETAILS: '/kyc-documents/individual/:individualId',
  VIEW_DOCUMENTS: '/kyc-documents/organization/:orgId/documents',
  CUSTOMERS_LIST: '/customers-list',
  INDIVIDUAL_CUSTOMER_DETAILS: '/customers-list/individual/:individualId',
  ORGANIZATION_CUSTOMER_DETAILS: '/customers-list/organization/:orgId'
};

export const PAGE_NOT_FOUND = '/page-not-found';

export const ADMIN_PAGE_NOT_FOUND = '/admin/page-not-found';

export const HELP = '/help';

export const DASHBOARD = '/dashboard';

export const TRANSACTIONS = {
  TRANSACTIONS: '/transactions'
};

export const SUBSCRIPTIONS = {
  PLAN_DETAILS: '/subscription',
  CHOOSE_PLAN: '/subscription/choose-plan'
};

export const ORG_SETTINGS = '/org-settings';

export const REPORTS = '/reports';

export const QUICK_COLLECT = {
  CREATE_LINK: '/quick-collect/create-link',
  SUCCESS: '/quick-collect/success'
};

export const MEMBERS = {
  MEMBERS: '/members',
  ADD_MEMBER: '/members/add',
  EDIT_MEMBER: '/members/edit/:memberId',
  MEMBER_DETAILS: '/members/:memberId'
};
