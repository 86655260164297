import { useEffect, useState } from 'react';

import { DatePickerOptions, DatePickerType } from '@constants/groups';
import { DateRange, DatePickerProps } from '@types';
import { Button, Dropdown, Icon } from '@components';
import {
  checkIfDateIsCurrentDate,
  getNumberOfDaysBetweenTwoDates
} from '@utils/date';

import MonthPicker from './components/month-picker/MonthPicker';
import WeekPicker from './components/week-picker/WeekPicker';
import Yesterday from './components/yesterday/Yesterday';
import CustomPicker from './components/custom-picker/CustomPicker';

const DateRangePicker = ({
  title = 'Select Date',
  className,
  cancelButtonLabel,
  confirmButtonLabel,
  cancelButtonStyle,
  handleCancel,
  minDate,
  paymentLinkGeneratedOn,
  confirmButtonStyle,
  dateOptions = DatePickerOptions,
  handleConfirm,
  defaultDatePickerType,
  showPicker = true,
  defaultDateRange,
  minimumDateDifference = 1,
  showTitle = true,
  showHeading = false,
  customPickerStyle
}: DatePickerProps) => {
  const [selectedFilter, setSelectedFilter] = useState<DatePickerType>(
    defaultDatePickerType || DatePickerType.MONTHLY
  );

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null>(
    null
  );

  const handleFilterSelection = (filterValue: string) => {
    setSelectedFilter(filterValue as DatePickerType);
  };

  const handleDateRangeSelection = (data: DateRange) => {
    setSelectedDateRange(data);
  };

  const isConfirmButtonDisabled =
    !selectedDateRange?.endDate ||
    !selectedDateRange?.startDate ||
    getNumberOfDaysBetweenTwoDates(
      selectedDateRange?.startDate || new Date(),
      selectedDateRange?.endDate || new Date()
    ) < minimumDateDifference;

  useEffect(() => {
    setSelectedDateRange(defaultDateRange || null);
  }, [defaultDateRange, selectedFilter]);

  const modifiedDatePickerOptions = () =>
    dateOptions.map((option) => {
      let isDisabled = false;

      const currentDate = new Date();
      const isPaymentLinkGeneratedOnCurrentDay = checkIfDateIsCurrentDate(
        paymentLinkGeneratedOn ? new Date(paymentLinkGeneratedOn) : new Date()
      );

      if (minDate || isPaymentLinkGeneratedOnCurrentDay) {
        switch (option.value) {
          case DatePickerType.YESTERDAY:
            isDisabled =
              (minDate && minDate >= currentDate) ||
              isPaymentLinkGeneratedOnCurrentDay;
            break;
          default:
            break;
        }
      }

      return { ...option, isDisabled };
    });

  // eslint-disable-next-line consistent-return
  const renderPicker = () => {
    switch (selectedFilter) {
      case DatePickerType.MONTHLY:
        return (
          <MonthPicker
            handleDateRangeSelect={handleDateRangeSelection}
            minDate={minDate}
          />
        );
      case DatePickerType.WEEKLY:
        return <WeekPicker handleDateRangeSelect={handleDateRangeSelection} />;
      case DatePickerType.YESTERDAY:
        return (
          <Yesterday
            handleDateRangeSelect={handleDateRangeSelection}
            minDate={minDate}
          />
        );
      case DatePickerType.CUSTOM:
        return (
          <CustomPicker
            handleDateRangeSelect={handleDateRangeSelection}
            minDate={minDate}
            {...(defaultDateRange && {
              defaultDateRange
            })}
            minimumDateDifference={minimumDateDifference}
            className={customPickerStyle || ''}
          />
        );
      default:
    }
  };

  return (
    <div
      className={`flex min-w-83.5 flex-col justify-start p-6 text-sm font-normal ${
        className || ''
      }`}
    >
      {showTitle && (
        <div className="mb-6 w-full text-center text-base">{title}</div>
      )}
      {showHeading && (
        <div
          className="mb-6 flex w-full cursor-pointer gap-2.5 border-b py-4 !pt-0 text-base"
          role="presentation"
          onClick={handleCancel}
        >
          <Icon name="left" />
          {title}
        </div>
      )}
      {showPicker && (
        <div className="justify start mb-4 flex items-center gap-4">
          <div className="text-grey">Select Range : </div>
          <Dropdown
            currentFilter={selectedFilter}
            filterOptions={modifiedDatePickerOptions()}
            onSelectFilter={handleFilterSelection}
            className="!text-normal h-8.25 min-w-25 rounded-lg !border-none !bg-grey10"
            optionsStyle="!w-45.5"
          />
        </div>
      )}

      {renderPicker()}
      <div className="mt-6 flex justify-between">
        <Button
          handleClick={handleCancel}
          variant="transparent"
          size="none"
          className={`ml-4 text-base font-medium ${
            cancelButtonStyle && cancelButtonStyle
          }`}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          disabled={isConfirmButtonDisabled}
          handleClick={() => handleConfirm(selectedDateRange, selectedFilter)}
          variant="transparent"
          size="none"
          className={`mr-4 text-base font-medium ${confirmButtonStyle || ''} ${
            isConfirmButtonDisabled ? 'text-disabledContent' : 'text-content'
          }`}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default DateRangePicker;
