import { useRef, useState } from 'react';
import { Skeleton } from '@mui/material';

import { DropdownOption, DropdownWithRadioButtonProps } from 'types/dropdown';
import useOnClickOutside from '@utils/hooks';
import RadioButton from '@components/radio-button/RadioButton';
import SearchBar from '@components/searchbar/SearchBar';

import Button from '../button/Button';

const DropdownWithRadoiButton = (props: DropdownWithRadioButtonProps) => {
  const {
    filterLabel,
    dropdownId,
    currentFilter,
    filterOptions,
    onSelectFilter,
    className,
    optionsStyle,
    isLoading,
    loadingStyles,
    dropdownStyle,
    searchBarPlaceholder
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const ref = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((isOpen) => !isOpen);
  };

  const onClickFilter = (option: string) => {
    if (option !== currentFilter) {
      onSelectFilter(option);
      toggleDropdown();
    }
  };

  const selectedFilter: DropdownOption | undefined = filterOptions?.find(
    (option) => option.value === currentFilter
  );

  const filteredOptions: DropdownOption[] = searchTerm
    ? filterOptions.filter((option: DropdownOption) =>
        option.label?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : filterOptions;

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
  });

  if (isLoading)
    return (
      <div className={loadingStyles || 'w-full'}>
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div className={`relative ${className || ''}`} ref={ref}>
      <div className="flex items-center gap-2">
        {filterLabel && (
          <span className="text-base font-medium text-grey">{filterLabel}</span>
        )}
        <Button
          data-testid={dropdownId}
          variant="tertiary"
          handleClick={toggleDropdown}
          size="medium"
          label={selectedFilter?.label}
          rightIconName="down-icon-dark"
          className={`min-w-166 justify-between border ${className}`}
          labelStyle={
            !selectedFilter?.label
              ? '!text-base text-grey !font-normal'
              : '!font-normal'
          }
          rightIconStyle={isDropdownOpen ? 'rotate-180' : ''}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={`absolute z-20 mt-1 w-55 origin-top-right rounded-md border border-grey40 bg-theme py-1 shadow-sm ${
            dropdownStyle || 'right-0'
          }`}
        >
          <div className="border-b px-6 py-3 text-sm font-medium text-grey">
            {filterLabel}
          </div>
          <div className="mt-3 px-6">
            <SearchBar
              onSearch={(searchValue) => {
                if (searchTerm === searchValue) return;
                setSearchTerm(searchValue);
              }}
              placeholder={searchBarPlaceholder}
              value={searchTerm}
            />
          </div>
          <div className={optionsStyle || ''}>
            {filteredOptions.length > 0 ? (
              filteredOptions?.map((option) => (
                <div
                  id={`${filterLabel || 'dropdown'}_${option.value}`}
                  key={option?.id}
                  className={`mt-3 flex items-center justify-between px-6 py-2 text-sm ${
                    option.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                >
                  <RadioButton
                    isSelected={option.value === selectedFilter?.value}
                    onChange={(e) => onClickFilter(e.target.value)}
                    label={option.label || ''}
                    value={option.value}
                    key={`option_${option.value}`}
                    labelStyle="!text-content !text-sm font-medium"
                  />
                </div>
              ))
            ) : (
              <div className="mt-3 flex h-40 items-center justify-center px-6 text-base text-grey">
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownWithRadoiButton;
