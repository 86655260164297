import { AppStore, Logo, PlayStore } from '@images';

const MobileLayout = () => (
  <div className="flex size-full items-center justify-center bg-grey10 px-8">
    <div className="flex items-center gap-8 rounded-lg bg-white p-8 shadow-md">
      <div className="rounded-full p-4 shadow-lg">
        <Logo className="size-12 min-w-12" />
      </div>
      <div className="flex flex-col text-lg font-medium">
        <div>Please download Cofee mobile app</div>
        <div
          className="mt-4 flex cursor-pointer items-center gap-4 rounded-md bg-content px-4 py-2 text-white"
          onClick={() => window.location.replace('https://cofee.life/a/i')}
          role="presentation"
        >
          <PlayStore className="size-8" />
          <div className="flex flex-col">
            <div className="text-xs">Get it on</div>
            <div className="text-sm font-semibold">Google Play</div>
          </div>
        </div>
        <div
          className="mt-4 flex cursor-pointer items-center gap-4 rounded-md bg-content px-4 py-2 text-white"
          onClick={() => window.location.replace('https://cofee.life/a/i')}
          role="presentation"
        >
          <AppStore className="size-8" />
          <div className="flex flex-col">
            <div className="text-xs">Get it on</div>
            <div className="text-sm font-semibold">App Store</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MobileLayout;
