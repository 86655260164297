import { useEffect, useState } from 'react';

import { DropdownOption } from 'types/dropdown';

import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import Icon from '../icon/Icon';

interface MultiSelectFilterProps {
  label: string;
  options: DropdownOption[];
  onApply: (selectedItems: string[]) => void;
  selectedOptions: string[];
}

type SelectedFilterValues = string[];

const MultiSelectFilter = (props: MultiSelectFilterProps) => {
  const { label, options, onApply, selectedOptions } = props;

  const [isOpen, setOpen] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] =
    useState<SelectedFilterValues>(selectedOptions);

  const onCloseFilter = () => {
    setOpen(false);
    setSelectedItems(selectedOptions);
  };

  const onOpenFilter = () => {
    setOpen(true);
  };

  const toggleFilter = () => {
    if (isOpen) {
      onCloseFilter();
    } else {
      onOpenFilter();
    }
  };

  const onClearAllFilters = () => {
    setSelectedItems([]);
  };

  const onApplyFilters = () => {
    onApply(selectedItems);
    toggleFilter();
  };

  const onSelectFilterItem = (item: DropdownOption, isChecked: boolean) => {
    let updatedItems;
    if (isChecked) {
      updatedItems = [...selectedItems, item.value];
    } else {
      updatedItems = selectedItems.filter((oldItem) => oldItem !== item.value);
    }
    setSelectedItems(updatedItems);
  };

  const onSelectAll = (isChecked: boolean) => {
    let updatedItems: string[];
    if (isChecked) {
      updatedItems = options.map((option) => option.value);
    } else {
      updatedItems = [];
    }
    setSelectedItems(updatedItems);
  };

  const renderOptionsCard = (item: DropdownOption) => (
    <div key={item.id} className="px-6 py-2.5">
      <Checkbox
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSelectFilterItem(item, e.target.checked)
        }
        label={item.label}
        isChecked={selectedItems?.includes(item.value)}
        labelStyle="!text-content leading-6"
      />
    </div>
  );

  useEffect(() => {
    setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  const renderSelectAll = () => {
    const optionsLength = options?.length;
    const selectedFieldItemsLength = selectedItems.length;
    return (
      <Checkbox
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSelectAll(e.target.checked)
        }
        label="Select All"
        isChecked={selectedFieldItemsLength === optionsLength}
        labelStyle="!text-content leading-6"
        checkboxStyle="mr-3"
      />
    );
  };

  return (
    <div className="relative min-w-65">
      <div
        role="presentation"
        onClick={toggleFilter}
        className="relative flex h-11 cursor-pointer items-center rounded-lg border px-4 hover:border-grey"
      >
        <span>{label}</span>
        <Icon
          name="up"
          className={`absolute right-4 ${isOpen ? '' : 'rotate-180'}`}
        />
      </div>
      {isOpen && (
        <div className="absolute right-0 z-10 mt-1 flex min-w-325 origin-top-right rounded-lg bg-theme shadow-multiFieldFilterShadow">
          <div className="w-full">
            <div className="flex items-center justify-between border-b border-b-grey30 px-2 py-0.5 text-base font-medium leading-6 text-content">
              <span className="pl-4 text-sm">Filter By {label}</span>
              <Button
                label="Clear All"
                variant="tertiary"
                handleClick={onClearAllFilters}
                className="!border-none"
                labelStyle="text-grey !font-normal"
              />
            </div>
            <div className="border-b border-b-grey30 px-6 py-3">
              {renderSelectAll()}
            </div>
            <div className="border-b border-b-grey30">
              {options.map((item) => renderOptionsCard(item))}
            </div>
            <div className="px-6 py-3">
              <Button
                label="Apply Filter"
                handleClick={onApplyFilters}
                className=" w-full"
                labelStyle="text-sm"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectFilter;
