import { useEffect, useState } from 'react';
import { Range, RangeKeyDict } from 'react-date-range';

import { formatISODate, getNumberOfDaysBetweenTwoDates } from '@utils/date';
import Calendar from '@components/calendar/Calendar';
import { PickerProps } from '@types';

const CustomPicker = ({
  handleDateRangeSelect,
  minDate,
  defaultDateRange,
  minimumDateDifference = 1,
  className
}: PickerProps) => {
  const [selectedDate, setSelectedDates] = useState<Range>({
    startDate: defaultDateRange?.startDate || new Date(),
    endDate: defaultDateRange?.endDate || new Date(),
    key: 'selection'
  });

  const handleDateChange = (dateObject: RangeKeyDict) => {
    const { selection } = dateObject;
    setSelectedDates(selection);
  };

  const showMinimumDateWarning =
    getNumberOfDaysBetweenTwoDates(
      selectedDate?.startDate || new Date(),
      selectedDate?.endDate || new Date()
    ) < minimumDateDifference;

  useEffect(() => {
    if (selectedDate?.startDate && selectedDate?.endDate)
      handleDateRangeSelect({
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div className={className || ''}>
      <div className="text-sm font-normal text-grey">Start Date - End Date</div>
      <div className="relative mt-3 flex h-8.25 w-full items-center justify-between rounded-lg bg-grey10 px-3 text-sm text-content">
        <div>
          {selectedDate
            ? `${formatISODate({
                date: selectedDate.startDate || new Date(),
                format: 'DD MMM YYYY'
              })} - ${formatISODate({
                date: selectedDate.endDate || new Date(),
                format: 'DD MMM YYYY'
              })}`
            : 'Select Date'}
        </div>
        {showMinimumDateWarning && (
          <div className="absolute top-8 text-xs text-error50">
            {`Minimum dates should be ${minimumDateDifference}`}
          </div>
        )}
      </div>
      <div className="mt-1">
        <Calendar
          handleChange={handleDateChange}
          startDate={selectedDate.startDate}
          endDate={selectedDate.endDate}
          maxDate={new Date()}
          minDate={minDate}
        />
      </div>
    </div>
  );
};

export default CustomPicker;
