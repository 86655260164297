import { useSelector } from 'react-redux';

import UserPermissionTypes from '@constants/permissions';
import useCheckPermission from '@hooks/checkPermission';
import { Group } from '@types';
import { PaymentCollectionType } from '@features/group-management/constants';
import { RoleType } from '@features/team-management/constants';
import { GroupDummy } from '@images';

import Icon from '../icon/Icon';
import theme from '../../../themes/colors';
import ProgressBar from '../progress-bar/ProgressBar';
import Tooltip from '../tooltip/Tooltip';

interface GroupCardProps {
  groupCardId?: string;
  groupDetails: Group;
  className?: string;
  handleGroupCardClick: () => void;
  onClickEdit: (e: React.MouseEvent) => void;
}

const GroupCard = ({
  groupCardId,
  groupDetails,
  className,
  handleGroupCardClick,
  onClickEdit
}: GroupCardProps) => {
  const { checkPermission } = useCheckPermission();

  const received: number = Number(groupDetails.pay_received);
  const due: number = Number(groupDetails.pay_due);
  const total: number = received + due;
  const currentProgress: number =
    total === 0 ? 0 : Math.round((received / total) * 100);

  const hasGroupEditAccess = checkPermission([UserPermissionTypes.GROUP_EDIT]);

  const { currentOrg } = useSelector((state: any) => state.user);
  const isAttendanceManager = currentOrg.role === RoleType.ATTENDANCE_MANAGER;

  const showEditGroupIcon =
    hasGroupEditAccess &&
    !(
      groupDetails.interval === PaymentCollectionType.ONCE &&
      !groupDetails.is_active &&
      groupDetails.end_date &&
      new Date(groupDetails.end_date).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0)
    );

  const renderGroupLogo = () => (
    <div>
      {!groupDetails.image_url ? (
        <GroupDummy className="size-12 rounded-20" />
      ) : (
        <img
          src={groupDetails.image_url}
          alt="Logo"
          className="size-12 rounded-20 border"
        />
      )}
    </div>
  );

  const renderGroupName = () => (
    <div className="flex h-12.5 max-w-67.5 flex-col justify-between md:max-w-35 lg:max-w-33 xl:max-w-32 desktop:max-w-42 xxl:max-w-48 3xl:max-w-57 4xl:max-w-63">
      <div className="truncate break-words text-base font-bold text-content">
        {groupDetails.name}
      </div>
      <div className="text-xs font-medium text-subText">
        {`${groupDetails.member_count} ${
          groupDetails.member_count === 1 ? 'MEMBER' : 'MEMBERS'
        }`}
      </div>
    </div>
  );

  const renderGroupCreationIncompleteWarning = () => (
    <>
      <Icon
        name="warning"
        size="24"
        data-tooltip-id={`incomplete-group-info-${groupDetails.id}`}
      />
      <Tooltip
        id={`incomplete-group-info-${groupDetails.id}`}
        place="bottom"
        className="max-w-67.5"
      >
        <div className="flex flex-col items-center justify-center gap-2 font-normal">
          <div className="text-sm font-medium text-white">
            Group creation incomplete
          </div>
          <div className="text-xs text-grey40">
            Please review and complete all required fields to finish group
            creation.
          </div>
        </div>
      </Tooltip>
    </>
  );

  return (
    <div
      data-testid={groupCardId}
      className={`card-shadow group relative min-h-33 w-full cursor-pointer rounded-xl bg-theme p-4 hover:bg-grey1 ${
        !groupDetails.is_active && 'opacity-40'
      } ${className || ''}`}
      role="presentation"
      onClick={handleGroupCardClick}
    >
      <div className="flex justify-between gap-2">
        <div className="flex gap-3">
          {renderGroupLogo()}
          {renderGroupName()}
        </div>
        {!groupDetails.amount && renderGroupCreationIncompleteWarning()}
      </div>
      {!isAttendanceManager && (
        <>
          <ProgressBar
            progress={currentProgress}
            bgColor={theme.grey50}
            progressBarcolor={theme.primary}
            height={3}
            wrapperStyle="my-4"
          />
          <div className="flex items-center justify-between text-sm text-grey">
            Due Amount
            <div className="flex items-center text-sm text-content">
              <Icon
                name="rupee"
                size="none"
                className="mr-1 size-3"
                fill={theme.content}
              />
              {groupDetails.pay_due}
            </div>
          </div>
        </>
      )}
      {showEditGroupIcon && (
        <div
          role="presentation"
          onClick={onClickEdit}
          className="invisible absolute right-4 top-4 flex size-6 items-center justify-center rounded-2xl bg-theme shadow-md group-hover:visible"
        >
          <Icon
            name="edit"
            size="none"
            className="size-3.5"
            stroke={theme.grey}
          />
        </div>
      )}
    </div>
  );
};
export default GroupCard;
