import { FeeCategoryDataType, FeeConfigurationType } from '@types';

export type GroupByIdResponse = {
  id: string;
  name: string;
  image_url: string;
  amount: string;
  frequency: string;
  interval_frequency: number;
  is_recurring: boolean;
  is_active: boolean;
  activation_date: string;
  member_count: number;
  pay_received: string;
  pay_due: string;
  allow_activation_date_update: boolean;
  payment_list: PaymentList[];
  is_payment_link_sent: boolean;
  is_split_payment: boolean;
  pagination: Pagination;
  next_due_at: string;
  remind_again: boolean;
  collection_started_at: string;
  created_at: string;
  start_date: any;
  end_date: any;
  is_attendance_tracking_enabled: boolean;
  is_send_attendance_alert_enabled: boolean;
  work_days: any;
  is_attendance_marked: boolean;
  is_fee_configured: boolean;
  group_fee_amount_configuration: any;
};

export type PaymentList = {
  payment_due: string;
  payment_received: string;
  paid_member_count: number;
  total_member_count: number;
  payment_month: string;
  payment_year: string;
};

export type Pagination = {
  page: number;
  limit: number;
  total_rows: number;
  last_page: boolean;
};

export enum MemberStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export type AddFromMembersListItem = {
  id: string;
  name: string;
  mobile: string;
  countryCode: string;
  admissionNumber: string;
  dueAmount: string;
  dueDate: string;
  isAlreadyPresent: boolean;
  feeCategoryData: FeeCategoryDataType[];
};

export type AddMemberModeType = 'File' | 'Manual' | 'MemberList';

export interface AmountChangeParams {
  value: string;
  index: number;
}

export type GetFeeCategoryDataFromConfig = (
  props?: FeeConfigurationType[]
) => FeeCategoryDataType[];

export type FeeCategoryInputType = {
  category: string;
  amount: string;
};
