import { ApprovedIcon, PendingIcon, RejectIcon } from '@icons';
import { TabPill } from 'types/tabs';

export enum DOCTYPE {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  KYC_PENDING = 'kyc_pending', // lite without BANK
  KYC_SUBMITTED = 'kyc_submitted', // lite gave docs and BANK, not approved
  VERIFICATION_PENDING = 'verification_pending', // rapid onboard with no BANK and PAN
  VERIFICATION_SUBMITTED = 'verification_submitted' // rapid onboard with BANK and pan, not approved
}

export enum DOCFILTERTYPE {
  ALL = '',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  KYC_PENDING = 'kyc_pending',
  KYC_SUBMITTED = 'kyc_submitted',
  VERIFICATION_PENDING = 'verification_pending',
  VERIFICATION_SUBMITTED = 'verification_submitted'
}

export const documentsFilterOptions = [
  { id: 1, label: 'All', value: DOCFILTERTYPE.ALL },
  { id: 2, label: 'Pending', value: DOCFILTERTYPE.PENDING },
  { id: 4, label: 'Approved', value: DOCFILTERTYPE.APPROVED },
  { id: 3, label: 'Rejected', value: DOCFILTERTYPE.REJECTED }
];

export const superAdminOrganizationsTableColumn = [
  {
    Header: 'Merchant ID',
    accessor: 'merchantId',
    width: '14%'
  },
  { Header: 'Created At', accessor: 'createdAt', width: '14%' },
  { Header: 'Mobile Number', accessor: 'mobileNumber', width: '14%' },
  { Header: 'Organization', accessor: 'organizationName', width: '25%' },
  { Header: 'PAN', accessor: 'pan', width: '12%' },
  { Header: 'Status', accessor: 'status', width: '10%' },
  { Header: 'KYC Documents', accessor: 'kycDocuments' }
];

export const bankAdminOrganizationsTableColumn = [
  {
    Header: 'Merchant ID',
    accessor: 'merchantId',
    width: '15%'
  },
  { Header: 'Created At', accessor: 'createdAt', width: '15%' },
  { Header: 'Mobile Number', accessor: 'mobileNumber', width: '15%' },
  { Header: 'Organization', accessor: 'organizationName', width: '25%' },
  { Header: 'PAN', accessor: 'pan', width: '15%' },
  { Header: 'KYC Documents', accessor: 'kycDocuments' }
];

export const superAdminKYCDocumentsColumn = [
  { Header: 'Document', accessor: 'document', width: '20%' },
  { Header: 'File', accessor: 'file', width: '50%' },
  { Header: 'Status', accessor: 'status', width: '20%' },
  { Header: 'Actions', accessor: 'actions' }
];

export const bankAdminKYCDocumentsColumn = [
  { Header: 'Document', accessor: 'document' },
  { Header: 'File', accessor: 'file' },
  { Header: 'Actions', accessor: 'actions' }
];

export const StatusLabel = {
  [DOCTYPE.PENDING]: 'Pending',
  [DOCTYPE.APPROVED]: 'Approved',
  [DOCTYPE.REJECTED]: 'Rejected',
  [DOCTYPE.KYC_SUBMITTED]: 'KYC Submitted',
  [DOCTYPE.KYC_PENDING]: 'KYC Pending',
  [DOCTYPE.VERIFICATION_PENDING]: 'Verification Pending',
  [DOCTYPE.VERIFICATION_SUBMITTED]: 'Verification Submitted'
};

export enum DOCUMENTS {
  BUSINESS_LICENSE = 'BUSINESS_LICENSE',
  PAN_CARD = 'PAN',
  GST_CERTIFICATE = 'GST_CERTIFICATE'
}

// View Documents Page
export const Icons = {
  [DOCTYPE.APPROVED]: { Icon: ApprovedIcon, tooltipText: 'Approved' },
  [DOCTYPE.PENDING]: { Icon: PendingIcon, tooltipText: 'Pending' },
  [DOCTYPE.REJECTED]: { Icon: RejectIcon, tooltipText: 'Rejected' },
  [DOCTYPE.KYC_PENDING]: { Icon: PendingIcon, tooltipText: 'KYC Pending' },
  [DOCTYPE.KYC_SUBMITTED]: { Icon: PendingIcon, tooltipText: 'KYC Submitted' },
  [DOCTYPE.VERIFICATION_PENDING]: {
    Icon: PendingIcon,
    tooltipText: 'Verification Pending'
  },
  [DOCTYPE.VERIFICATION_SUBMITTED]: {
    Icon: PendingIcon,
    tooltipText: 'Verification Submitted'
  }
};

export enum DocPageFlows {
  VIA_ORG_DETAILS_PAGE = 'via_org_details_page',
  VIA_ORG_LIST_PAGE = 'via_org_list_page'
}

export const rejectDocOptions = [
  {
    id: 1,
    label: 'Document is blurry',
    value: 'Document is blurry'
  },
  {
    id: 2,
    label: 'Wrong document submitted',
    value: 'Wrong document submitted'
  },
  {
    id: 3,
    label: 'Mismatch in document info',
    value: 'Mismatch in document info'
  },
  { id: 4, label: 'Other', value: 'other' }
];

export enum AdminTypes {
  SUPER_ADMIN = 'super_admin',
  BANK_ADMIN = 'bank_admin'
}

export const adminTabs: TabPill[] = [
  { id: 1, label: 'Organizations' },
  { id: 2, label: 'Individual' }
];

export const superAdminIndividualTableColumn = [
  { Header: 'User ID', accessor: 'userId', width: '17%' },
  { Header: 'Created At', accessor: 'createdAt', width: '17%' },
  { Header: 'Mobile Number', accessor: 'mobileNumber', width: '17%' },
  { Header: 'Profile Name', accessor: 'profileName', width: '20%' },
  { Header: 'PAN', accessor: 'pan', width: '17%' },
  { Header: 'Status', accessor: 'status' }
];

export const bankAdminIndividualTableColumn = [
  { Header: 'User ID', accessor: 'userId', width: '20%' },
  { Header: 'Created At', accessor: 'createdAt', width: '20%' },
  { Header: 'Mobile Number', accessor: 'mobileNumber', width: '18%' },
  { Header: 'Profile Name', accessor: 'profileName', width: '25%' },
  { Header: 'PAN', accessor: 'pan' }
];

export const OrganizationCustomersListColumn = [
  { Header: 'User ID', accessor: 'userId' },
  { Header: 'Profile Name', accessor: 'profileName' },
  { Header: 'Mobile Number', accessor: 'mobileNumber' },
  { Header: 'Plan', accessor: 'plan' },
  { Header: 'Organization', accessor: 'organization' },
  { Header: 'Status', accessor: 'status' }
];

export const organizationCustomerFilter = [
  { id: 1, label: 'Pending', value: DOCFILTERTYPE.PENDING },
  { id: 2, label: 'Approved', value: DOCFILTERTYPE.APPROVED },
  { id: 3, label: 'Rejected', value: DOCFILTERTYPE.REJECTED }
];

export const individualCustomerFilter = [
  { id: 1, label: 'KYC Pending', value: DOCFILTERTYPE.KYC_PENDING },
  { id: 2, label: 'KYC Submitted', value: DOCFILTERTYPE.KYC_SUBMITTED },
  {
    id: 3,
    label: 'Verification Pending',
    value: DOCFILTERTYPE.VERIFICATION_PENDING
  },
  {
    id: 4,
    label: 'Verification Submitted',
    value: DOCFILTERTYPE.VERIFICATION_SUBMITTED
  },
  { id: 5, label: 'Pending', value: DOCFILTERTYPE.PENDING },
  { id: 6, label: 'Approved', value: DOCFILTERTYPE.APPROVED },
  { id: 7, label: 'Rejected', value: DOCFILTERTYPE.REJECTED }
];
