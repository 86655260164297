import React from 'react';
import clsx from 'clsx';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { TableProps } from 'types';
import Checkbox from '@components/checkbox/Checkbox';

const CustomTable: React.FC<TableProps> = ({
  hasMultiSelect,
  columns,
  data,
  isRowClickRequired = false,
  rowDataStyle,
  headerDataStyle,
  selectedRow,
  handleMultiSelect,
  selectedRows,
  handleSelect,
  setLastRow,
  className,
  showNoResults = false,
  noResultText,
  disabledRowIds,
  disabledCellStyle = '!text-bgGrey60'
}) => {
  const isRowSelected = (rowId: string) => {
    if (selectedRow === rowId) return true;
    if (selectedRows) return selectedRows.includes(rowId);
    return false;
  };

  const isRowDisabled = (rowId: string) => disabledRowIds?.includes(rowId);

  const isAllRowSelected = () => {
    if (selectedRows && selectedRows.length > 0) {
      const selectableRows = data.filter((row) => !isRowDisabled(row.id));
      return selectableRows.every((row) => selectedRows.includes(row.id));
    }
    return false;
  };

  const assignLastRowForInfiniteScroll = (
    node: HTMLTableRowElement | null,
    rowId: number
  ) => {
    if (data.length && data[data.length - 1].id === rowId) {
      setLastRow?.(node);
    }
  };

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: `'DM Sans', 'sans-serif'`
      }
    }
  });

  const commonHeaderDataStyle =
    '!border-b !border-b-greyStroke !bg-grey10 !text-base !font-medium !text-black';

  const getTableRowStyle = (id: string) =>
    clsx(
      'group !border-b !border-b-greyStroke bg-white',
      isRowClickRequired && !isRowDisabled(id) ? 'cursor-pointer' : '',
      isRowDisabled(id)
        ? `cursor-not-allowed bg-bgGrey70`
        : 'hover:bg-primaryLite8',
      isRowSelected(id) ? `!bg-primaryLite7` : 'inherit',
      isRowSelected(id) && hasMultiSelect ? '!bg-white' : 'inherit'
    );

  const getTableCellStyle = (id: string, columnStyle?: string) =>
    clsx(
      '!text-base',
      columnStyle || '',
      rowDataStyle || '',
      isRowDisabled(id) ? disabledCellStyle : ''
    );

  const handleSelectAll = () => {
    if (handleMultiSelect) {
      if (isAllRowSelected()) {
        const removedRows = data
          .filter((row) => !isRowDisabled(row.id))
          .map((row) => row.id);
        handleMultiSelect([], removedRows);
      } else {
        const selectableRows = data
          .filter((row) => !isRowDisabled(row.id))
          .map((row) => row.id);
        handleMultiSelect(selectableRows, []);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        className={`table-scroll h-full bg-white ${className || ''}`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow className="h-11 leading-5">
              {hasMultiSelect && (
                <TableCell
                  className={`w-5 ${commonHeaderDataStyle} ${
                    headerDataStyle || ''
                  }`}
                >
                  <Checkbox
                    handleChange={handleSelectAll}
                    isChecked={isAllRowSelected()}
                    checkIconUncheckedStyle="text-white fill-current opacity-100 border rounded-md border-borderGrey6"
                    inputUnCheckedStyle="border-none"
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  className={`${commonHeaderDataStyle} ${
                    headerDataStyle || ''
                  }`}
                  key={column.accessor}
                  size="small"
                >
                  {column.Header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showNoResults ? (
              <TableRow
                className={`group !border-b !border-b-greyStroke bg-white hover:bg-primaryLite8 ${
                  isRowClickRequired ? 'cursor-pointer' : ''
                }`}
              >
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  className="!text-sm font-normal italic !text-grey"
                >
                  {noResultText || 'No data to show'}
                </TableCell>
              </TableRow>
            ) : (
              data?.map((row) => (
                <TableRow
                  key={row.id}
                  ref={(node) => assignLastRowForInfiniteScroll(node, row.id)}
                  selected={
                    isRowClickRequired && !isRowDisabled(row.id)
                      ? isRowSelected(row.id)
                      : false
                  }
                  onClick={() => {
                    if (!isRowDisabled(row.id) && handleSelect)
                      handleSelect(row);
                  }}
                  className={getTableRowStyle(row.id)}
                >
                  {hasMultiSelect && (
                    <TableCell className={`${getTableCellStyle(row.id)} w-5`}>
                      <Checkbox
                        handleChange={() => {
                          if (!isRowDisabled(row.id) && handleSelect)
                            handleSelect(row);
                        }}
                        isChecked={isRowSelected(row.id)}
                        checkIconUncheckedStyle="text-white fill-current opacity-100 border rounded-md border-borderGrey6"
                        inputUnCheckedStyle="border-none"
                      />
                    </TableCell>
                  )}
                  {columns.reduce((acc, column) => {
                    if (row[column.accessor] !== undefined) {
                      acc.push(
                        <TableCell
                          {...(column.colSpan && { colSpan: column.colSpan })}
                          key={`${row.id}_${column.accessor}`}
                          className={getTableCellStyle(
                            row.id,
                            column.style || ''
                          )}
                          width={column.width || ''}
                        >
                          {row[column.accessor]}
                        </TableCell>
                      );
                    }
                    return acc;
                  }, [] as JSX.Element[])}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default CustomTable;
