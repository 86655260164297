import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ADMIN } from '@constants/routes';

const ViewDocuments = lazy(
  () => import('@features/admin/pages/view-documents/ViewDocuments')
);

const OrganizationDetails = lazy(
  () => import('@features/admin/pages/organization-details/OrganizationDetails')
);

const AdminHome = lazy(
  () => import('@features/admin/pages/admin-home/AdminHome')
);

const IndividualDetails = lazy(
  () => import('@features/admin/pages/individual-details/IndividualDetails')
);

const CustomersList = lazy(
  () => import('@features/admin/pages/customers-list/CustomersList')
);

const IndividualCustomerDetails = lazy(
  () =>
    import(
      '@features/admin/pages/individual-customer-details/IndividualCustomerDetails'
    )
);
const OrganizationCustomerDetails = lazy(
  () =>
    import(
      '@features/admin/pages/organization-customer-details/OrganizationCustomerDetails'
    )
);

export const AdminRoutes: RouteObject[] = [
  {
    path: ADMIN.KYC_DOCUMENTS,
    element: <AdminHome />
  },
  {
    path: ADMIN.VIEW_DOCUMENTS,
    element: <ViewDocuments />
  },
  { path: ADMIN.ORGANIZATION_DETAILS, element: <OrganizationDetails /> },
  { path: ADMIN.INDIVIDUAL_DETAILS, element: <IndividualDetails /> },
  { path: ADMIN.CUSTOMERS_LIST, element: <CustomersList /> },
  {
    path: ADMIN.INDIVIDUAL_CUSTOMER_DETAILS,
    element: <IndividualCustomerDetails />
  },
  {
    path: ADMIN.ORGANIZATION_CUSTOMER_DETAILS,
    element: <OrganizationCustomerDetails />
  }
];

export default AdminRoutes;
