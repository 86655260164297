import { RadioButtonProps } from 'types/button';

const labelStyles = {
  common: 'text-sm text-grey ml-4'
};

const inputStyles = {
  common: 'h-4 w-4 border-subText'
};

const RadioButton = ({
  isSelected,
  label,
  onChange,
  value,
  labelStyle,
  buttonId,
  element,
  ...props
}: RadioButtonProps) => (
  <div className="flex items-center">
    <input
      type="radio"
      onChange={onChange}
      id={buttonId || label}
      data-testid={buttonId}
      value={value}
      {...props}
      checked={isSelected}
      // need to change accent color to primary color
      className={`accent-green-700 ${inputStyles.common}`}
    />
    <label htmlFor={label} className={`${labelStyles.common} ${labelStyle}`}>
      <div>{element || label}</div>
    </label>
  </div>
);

export default RadioButton;
