import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { sendMixpanelEvent, trackPageView, Event } from '@core/analytics';

import { cofeeSupportMail, cofeeSupportMailSubject } from '@constants/cofee';
import { RequestRejectedImg } from '@images';

const RequestRejected = () => {
  const { currentOrg } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (currentOrg?.id)
      trackPageView('Organization Rejected', {
        organization_name: currentOrg.name
      });
  }, [currentOrg]);

  const onClickContactSupport = () => {
    sendMixpanelEvent(Event.CONTACT_SUPPORT_CLICKED, {
      organization_name: currentOrg.name
    });
  };

  return (
    <>
      <div className="flex h-[calc(100vh-300px)] flex-col items-center justify-center">
        <div className="flex flex-col justify-start">
          <RequestRejectedImg className="h-57 w-73" />
          <div className="mt-12 flex justify-center text-2xl font-medium text-content">
            Request Rejected
          </div>
          <div className="mt-4 text-base font-normal text-grey">
            Your request to create account has been rejected.
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center text-sm font-medium leading-4 text-grey">
          Need more help?
          <div className="mb-2 mt-3 font-normal">Talk to our support team</div>
        </div>
        <a
          href={`mailto:${cofeeSupportMail}?subject=${encodeURIComponent(
            cofeeSupportMailSubject
          )}`}
          className="text-base font-medium text-primary underline"
          onClick={onClickContactSupport}
        >
          Contact support
        </a>
      </div>
    </>
  );
};

export default RequestRejected;
