import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { AUTH } from '@constants/routes';
import AuthLayout from '@layouts/auth-layout/AuthLayout';

const LoginPage = lazy(() => import('./pages/login/Login'));

const SelectAccountPage = lazy(
  () => import('./pages/account-selection/AccountSelection')
);

const SelectPaymentPage = lazy(
  () => import('./pages/payment-selection/PaymentSelection')
);

const DocumentUpload = lazy(
  () => import('./pages/document-upload/DocumentUpload')
);

const CreateBranch = lazy(() => import('./pages/create-branch/CreateBranch'));

const PrivacyPolicyPage = lazy(
  () => import('./pages/terms-and-conditions/PrivacyPolicy')
);

const TermsNCondtionsPage = lazy(
  () => import('./pages/terms-and-conditions/TermsNConditions')
);

const AcceptInvite = lazy(() => import('./pages/accept-invite/AcceptInvite'));

const OrganizationSwitching = lazy(
  () => import('./pages/organization-selection/OrganizationSelection')
);

export const AuthRoutes: RouteObject = {
  children: [
    {
      element: <AuthLayout />,
      children: [
        { path: AUTH.LOGIN, element: <LoginPage /> },
        { path: AUTH.SELECT_ACCOUNT, element: <SelectAccountPage /> },
        { path: AUTH.SELECT_PAYMENT, element: <SelectPaymentPage /> },
        { path: AUTH.DOCUMENT_UPLOAD, element: <DocumentUpload /> },
        { path: AUTH.CREATE_BRANCH, element: <CreateBranch /> }
      ]
    },
    { path: AUTH.TERMS_AND_CONDITIONS, element: <TermsNCondtionsPage /> },
    { path: AUTH.PRIVACY_POLICY, element: <PrivacyPolicyPage /> },
    { path: AUTH.ACCEPT_INVITE, element: <AcceptInvite /> },
    { path: AUTH.ORG_SELECTION, element: <OrganizationSwitching /> }
  ]
};

export default AuthRoutes;
