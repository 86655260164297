import {
  UserProfileCustomField,
  UserProfileCustomFieldType,
  PossibleBooleanValues
} from '@features/member-management/types';
import { BloodGroups } from '@types';

import { snakeToCamel } from './stringFormat';

export const getDefaultUserProfileTemplateHeaders = (
  defaultTemplateHeaders: string[],
  customFields: UserProfileCustomField[]
) => {
  const templateHeaders = [...defaultTemplateHeaders];
  if (customFields) {
    customFields.forEach((field) => {
      templateHeaders.push(snakeToCamel(field.key));
    });
  }
  return templateHeaders;
};

export const getUserProfileDefaultValue = (
  field: UserProfileCustomFieldType
) => {
  switch (field) {
    case UserProfileCustomFieldType.IS_INSURED:
      return PossibleBooleanValues.NO;
    case UserProfileCustomFieldType.BLOOD_GROUP:
      return BloodGroups.A_POSITIVE;
    default: {
      return '';
    }
  }
};

export const getTemplateUserProfileDummayData = (
  userProfileDummyData: string[],
  customFields: UserProfileCustomField[]
) => {
  const templateDummayData = [...userProfileDummyData];

  if (customFields) {
    customFields.forEach((field) => {
      const defaultValue = getUserProfileDefaultValue(field.key);
      templateDummayData.push(defaultValue);
    });
  }
  return templateDummayData;
};
