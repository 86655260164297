import { memo } from 'react';
import clsx from 'clsx';

import { AvatarWithNameProps } from '@types';
import Avatar from '@components/avatar/Avatar';
import testId from '@constants/testId';

const AvatarWithName = ({
  name,
  subText,
  imageUrl,
  bgColor,
  wrapperClass = '',
  avatarClass = '',
  nameClass = '',
  subTextClass = ''
}: AvatarWithNameProps) => (
  <div key={name} className={clsx('flex items-center gap-4', wrapperClass)}>
    <Avatar
      name={name}
      imageUrl={imageUrl}
      className={clsx('size-10 rounded-full', avatarClass)}
      bgColor={bgColor}
    />
    <div>
      <div
        className={clsx('text-base font-medium text-content', nameClass)}
        data-testid={testId.header.profileDropdown.dropdownName}
      >
        {name}
      </div>
      <div
        className={clsx(
          'text-[13px] font-medium leading-4 text-grey',
          subTextClass
        )}
        data-testid={testId.header.profileDropdown.dropdownNumber}
      >
        {subText}
      </div>
    </div>
  </div>
);

export default memo(AvatarWithName);
