import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface MemberState {
  currentBranchFilter: {
    branchId: string;
    branchName: string;
  };
  currentGroupIds: string[];
  currentSearchTerm: string;
}
const initialState: MemberState = {
  currentBranchFilter: {
    branchId: '',
    branchName: ''
  },
  currentGroupIds: [],
  currentSearchTerm: ''
};

export const memberSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    updateBranchFilter: (
      state,
      action: PayloadAction<{ branchId: string; branchName: string }>
    ) => {
      state.currentBranchFilter = action.payload;
    },
    updateCurrentGroupIds: (state, action: PayloadAction<string[]>) => {
      state.currentGroupIds = action.payload;
    },
    updateCurrentSearchTerm: (state, action: PayloadAction<string>) => {
      state.currentSearchTerm = action.payload;
    },
    resetMemberState: () => initialState
  }
});

export const {
  updateBranchFilter,
  updateCurrentGroupIds,
  updateCurrentSearchTerm,
  resetMemberState
} = memberSlice.actions;
export default memberSlice.reducer;
