import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { MEMBERS } from '@constants/routes';

const Members = lazy(() => import('./pages/members/Members'));
const AddMember = lazy(() => import('./pages/add-member/AddMember'));
const EditMember = lazy(() => import('./pages/edit-member/EditMember'));
const MemberDetails = lazy(
  () => import('./pages/member-details/MemberDetails')
);

export const MemberRoutes: RouteObject[] = [
  {
    path: MEMBERS.MEMBERS,
    element: <Members />
  },
  {
    path: MEMBERS.ADD_MEMBER,
    element: <AddMember />
  },
  {
    path: MEMBERS.EDIT_MEMBER,
    element: <EditMember />
  },
  {
    path: MEMBERS.MEMBER_DETAILS,
    element: <MemberDetails />
  }
];

export default MemberRoutes;
