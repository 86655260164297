import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { RightArrowIcon } from '@icons';

import useBreadcrumbs from './useBreadcrumbs';

interface BreadcrumbProps {
  dynamicCrumbs?: Array<{ name: string; path: string }>;
}

const Breadcrumbs = (props: BreadcrumbProps) => {
  const { dynamicCrumbs } = props;

  let crumbs = useBreadcrumbs(!!dynamicCrumbs);
  crumbs = dynamicCrumbs || crumbs;

  return (
    <div className="flex items-center gap-2.5 py-2 text-xs font-medium">
      {crumbs.map(({ name, path }, index) =>
        index + 1 === crumbs.length ? (
          <span key={path} className="text-grey">
            {name}
          </span>
        ) : (
          <Fragment key={path}>
            <Link to={path} className="text-primary">
              {name}
            </Link>
            <RightArrowIcon className="size-3.5" />
          </Fragment>
        )
      )}
    </div>
  );
};

export default Breadcrumbs;
