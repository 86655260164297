import { OrgAndBranchId } from '@types';

export interface Group {
  id: string;
  name: string;
  is_active: boolean;
}

export type MemberType = {
  id: string;
  name: string;
  country_code: string;
  mobile: string;
  groups: Group[];
  guardian_name: string;
  admission_number: string;
};

export enum MemberFormFields {
  NAME = 'name',
  EMAIL = 'email',
  PRIMARY_COUNTRY_CODE = 'primaryCountryCode',
  PRIMARY_NUMBER = 'primaryNumber',
  ALTERNATE_COUNTRY_CODE = 'alternateCountryCode',
  ALTERNATE_NUMBER = 'alternateNumber',
  BLOOD_GROUP = 'bloodGroup',
  DATE_OF_BIRTH = 'dateOfBirth',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  COUNTRY = 'country',
  CITY = 'city',
  STATE = 'state',
  PINCODE = 'pincode',
  GUARDIAN_NAME = 'guardianName',
  ADMISSION_NUMBER = 'admissionNumber',
  ADMISSION_DATE = 'admissionDate',
  IS_INSURED = 'isInsured'
}

export enum MemberFormSections {
  PERSONAL_INFO = 'personalInfo',
  // ADDRESS = 'address',
  MORE_INFO = 'moreInfo'
}

export type IMemberFormFieldsState = {
  [MemberFormFields.NAME]: string;
  [MemberFormFields.EMAIL]: string;
  [MemberFormFields.PRIMARY_COUNTRY_CODE]: string;
  [MemberFormFields.PRIMARY_NUMBER]: string;
  [MemberFormFields.ALTERNATE_COUNTRY_CODE]: string;
  [MemberFormFields.ALTERNATE_NUMBER]: string;
  [MemberFormFields.DATE_OF_BIRTH]: string;
  [MemberFormFields.BLOOD_GROUP]: string;
  [MemberFormFields.ADDRESS_LINE_1]: string;
  [MemberFormFields.ADDRESS_LINE_2]: string;
  [MemberFormFields.COUNTRY]: string;
  [MemberFormFields.CITY]: string;
  [MemberFormFields.STATE]: string;
  [MemberFormFields.PINCODE]: string;
  [MemberFormFields.GUARDIAN_NAME]: string;
  [MemberFormFields.ADMISSION_NUMBER]: string;
  [MemberFormFields.ADMISSION_DATE]: string;
  [MemberFormFields.IS_INSURED]: string;
};

export type ICustomFields =
  | MemberFormFields.BLOOD_GROUP
  | MemberFormFields.IS_INSURED;

export type IFieldDisabledMap = {
  [key in MemberFormFields]?: boolean;
};

export type IMemberFormFieldsErrors = { [key in MemberFormFields]?: string };

export type IMemberFormFieldsValues =
  | IMemberFormFieldsState[keyof IMemberFormFieldsState]
  | undefined;

export type IHandleFieldValueChange = (
  field: MemberFormFields,
  value: IMemberFormFieldsValues
) => void;

export type UserProfileMemberInput = {
  name: string | null;
  primaryNumber: string | null;
  primaryNumberCode: string | null;
  altNumberCode: string | null;
  altNumber: string | null;
  email: string | null;
  dateOfBirth: string | null;
  bloodGroup: string | null;
  guardianName: string | null;
  admissionNumber: string | null;
  admissionDate: string | null;
  isInsured: string | null;
};

export enum UserProfileCustomFieldType {
  IS_INSURED = 'is_insured',
  BLOOD_GROUP = 'blood_group'
}

export type UserProfileCustomField = {
  key: UserProfileCustomFieldType;
  label: string;
  type: string;
};
export interface CreateMemberRequest {
  body: CreateMemberRequestBody;
  params: OrgAndBranchId;
}

export interface UpdateMemberRequest {
  body: CreateMemberRequestBody;
  params: OrgAndBranchId;
}

export interface CreateMemberRequestBody {
  branch_members: BranchMember[];
}

export interface BranchMember {
  name: string | null;
  mobile: string | null;
  country_code: string | null;
  alternate_mobile: string | null;
  secondary_country_code: string | null;
  email: string | null;
  admission_number: string | null;
  admission_date: string | null;
  dob: string | null;
  guardian_name: string | null;
  address_line_1?: string | null;
  address_line_2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  pincode?: string | null;
  custom_fields: CustomField[];
  groups?: Group[];
}

export interface CustomField {
  key: string;
  value: string | null;
}
export enum PossibleBooleanValues {
  YES = 'Yes',
  NO = 'No'
}
