import { SettlementMode } from '@constants/generic';
import { RoleType } from '@features/team-management/constants';
import { ImageForm } from '@types';

interface DummyGroup {
  // to be removed
  id: number;
  image_url: string;
}
export interface Branch {
  id: string;
  groups?: DummyGroup[]; // to be updated
  group_count: number;
  image_url: string;
  name: string;
}

export interface UpdateBranchRequest {}

export interface BranchInfo {
  branchId: string;
  branchName: string;
  branchImage?: string | File;
}

export interface BranchForm {
  branchName: string;
  branchImage?: ImageForm;
  settlementMode: SettlementMode.UPI | SettlementMode.BANK;
}

export interface UpiAccountData {
  upiId: string;
}

export interface BankAccountData {
  accountNumber: string;
  confirmAccountNumber: string;
  ifscCode: string;
}

export type AccountData = UpiAccountData | BankAccountData;

export enum UserStatus {
  PENDING = 'pending',
  REJECTED = 'rejected'
}

const roleOptions = [
  {
    id: RoleType.ORGANISATION_ADMIN,
    label: 'Organization Admin',
    value: RoleType.ORGANISATION_ADMIN
  },
  {
    id: RoleType.BRANCH_ADMIN,
    label: 'Branch Admin',
    value: RoleType.BRANCH_ADMIN
  },
  {
    id: RoleType.GROUP_ADMIN,
    label: 'Group Admin',
    value: RoleType.GROUP_ADMIN
  },
  {
    id: RoleType.FINANCE_ADMIN,
    label: 'Finance',
    value: RoleType.FINANCE_ADMIN
  },
  {
    id: RoleType.ATTENDANCE_MANAGER,
    label: 'Attendance Manager',
    value: RoleType.ATTENDANCE_MANAGER
  }
];

const userStatusOptions = [
  { id: UserStatus.PENDING, label: 'Pending', value: UserStatus.PENDING },
  { id: UserStatus.REJECTED, label: 'Rejected', value: UserStatus.REJECTED }
];

export const teamManagementInvitedMemberTableFilter = {
  userStatus: {
    id: 1,
    label: 'User Status',
    field: 'userStatus',
    options: userStatusOptions
  },
  role: {
    id: 2,
    label: 'Role',
    field: 'role',
    options: roleOptions
  }
};

export const teamManagementUserRolesTableFilter = {
  role: {
    id: 1,
    label: 'Role',
    field: 'role',
    options: roleOptions
  }
};

export interface OrgAndBranchId {
  organizationId: string;
  branchId: string;
}

export interface OrgBranchGroupIds extends OrgAndBranchId {
  groupId: string;
}

export interface OrgBranchGroupMemberIds extends OrgBranchGroupIds {
  memberId: string;
}

export interface UpdateGroupStatusParams extends OrgBranchGroupIds {
  status: string;
}

export interface OrgBranchOrderIds extends OrgAndBranchId {
  orderId: string;
}

export interface OrgBranchMemberIds extends OrgAndBranchId {
  memberId: string;
}
