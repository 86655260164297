import { ThankyouImg } from '@images';

const ThankyouPage = () => (
  <div className="flex h-[calc(100vh-83px)] w-full flex-col items-center justify-center">
    <ThankyouImg className=" h-60 w-122" />
    <div className="mt-8 text-3xl font-bold text-content">Thank You</div>
    <div className="mt-2 flex text-xl text-grey">
      We appreciate your interest in CoFee.
    </div>
    <div className="flex text-xl text-grey">We will contact you soon</div>
  </div>
);

export default ThankyouPage;
