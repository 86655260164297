import { useRef, useState } from 'react';
import { Skeleton } from '@mui/material';

import useOnClickOutside from '@utils/hooks';
import { DropdownOption, MultiSelectDropdownProps } from 'types/dropdown';

import Icon from '../icon/Icon';
import SearchBar from '../searchbar/SearchBar';
import Checkbox from '../checkbox/Checkbox';
import PlusNChipList from '../plus-n-chip-list/PlusNChipList';

const MultiSelectDropdown = ({
  label,
  labelStyle,
  searchBarPlaceholder,
  options,
  selectedOptions,
  onChange,
  className,
  displayChipsCount = 2,
  plusMoreClickRequired = true,
  isLoading,
  moreText,
  dropDownStyle,
  dropDownHeaderStyle,
  isChipCloseIconRequired,
  chipClassName = 'bg-white',
  plusNChipBgColor,
  disabledChipsClassName
}: MultiSelectDropdownProps) => {
  const ref = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const findIndex = (item: DropdownOption) =>
    selectedOptions.findIndex((option) => option.id === item.id);

  const filteredOptions: DropdownOption[] = searchValue
    ? options.filter((option: DropdownOption) =>
        option.label?.toLowerCase().includes(searchValue.toLowerCase())
      )
    : options;

  const isSelectAllChecked = filteredOptions?.every((filteredItem) =>
    selectedOptions.find((selectedItem) => filteredItem.id === selectedItem.id)
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectAllCheckedState = event.target.checked;
    let updatedOptions: DropdownOption[] = [];
    if (selectAllCheckedState) {
      const temp = filteredOptions.filter((item) => findIndex(item) < 0);
      updatedOptions.push(...temp);
      onChange(selectedOptions.concat(updatedOptions));
    } else {
      updatedOptions = selectedOptions.filter(
        (selectedItem) =>
          !filteredOptions.find(
            (filteredItem) => selectedItem.id === filteredItem.id
          )
      );
      onChange(updatedOptions);
    }
  };

  const handleOnChange = (
    element: DropdownOption,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkedState = event.target.checked;
    let updatedOptions: DropdownOption[];
    const index = findIndex(element);
    if (checkedState) {
      if (index < 0) {
        updatedOptions = [...selectedOptions, element];
        onChange(updatedOptions);
      }
    } else if (index >= 0) {
      updatedOptions = selectedOptions.filter(
        (option) => option.id !== element.id
      );
      onChange(updatedOptions);
    }
  };

  const handleOnRemove = (item: DropdownOption) => {
    const index = findIndex(item);
    let updatedOptions: DropdownOption[];
    if (index >= 0) {
      updatedOptions = selectedOptions.filter(
        (option) => option.id !== item.id
      );
      onChange(updatedOptions);
    }
  };

  const renderDropdownLabel = () => {
    const onClickPlusMoreText = () => setIsDropdownOpen(false);
    return (
      <>
        <span className={`text-base font-normal text-grey ${labelStyle}`}>
          {!selectedOptions.length && label}
        </span>
        <PlusNChipList
          selectedOptions={selectedOptions}
          handleOnRemove={handleOnRemove}
          plusMoreClickRequired={plusMoreClickRequired}
          displayChipsCount={displayChipsCount}
          chipClassName={chipClassName}
          tooltipChipClassName="bg-grey10"
          onClickPlusMoreText={onClickPlusMoreText}
          moreText={moreText}
          isCloseIconRequired={isChipCloseIconRequired}
          plusNChipBgColor={plusNChipBgColor}
          disabledChipsClassName={disabledChipsClassName}
        />
      </>
    );
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setSearchValue('');
  };

  const handleSearchBarChange = (searchTerm: string) => {
    setSearchValue(searchTerm);
  };

  useOnClickOutside(ref, () => {
    setIsDropdownOpen(false);
    setSearchValue('');
  });

  if (isLoading)
    return (
      <div className="w-full">
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div ref={ref} className={`relative w-full ${className || ''}`}>
      <div
        role="presentation"
        onClick={handleDropdownClick}
        className={`flex h-12.5 cursor-pointer items-center rounded-xl border bg-grey10 px-4 hover:border-grey ${dropDownHeaderStyle}`}
      >
        {renderDropdownLabel()}
        <Icon
          name="up"
          className={`absolute right-4 ${isDropdownOpen ? '' : 'rotate-180'}`}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={`absolute left-0 z-20 mt-1 w-full rounded-xl border border-whiteBorder bg-white p-4 shadow-multiSelectDropdownShadow 
                    ${dropDownStyle}`}
        >
          <SearchBar
            placeholder={searchBarPlaceholder}
            onSearch={handleSearchBarChange}
            value={searchValue}
          />
          {filteredOptions.length > 0 ? (
            <div className="mt-3 rounded bg-white py-2 shadow-dropdownOptionsShadow">
              <div className="border-b border-b-grey30 py-2">
                <Checkbox
                  handleChange={handleSelectAll}
                  label="Select All"
                  className="px-4"
                  labelStyle="text-base !text-content !font-medium"
                  checked={isSelectAllChecked}
                />
              </div>
              <div className="custom-scroll max-h-180 overflow-y-auto px-4">
                {filteredOptions.map((item) => (
                  <Checkbox
                    label={item.label}
                    key={item.value}
                    handleChange={(e) => handleOnChange(item, e)}
                    checked={findIndex(item) >= 0}
                    className="py-2"
                    labelStyle="!text-content text-base font-normal"
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="mt-3 flex h-14 items-center p-4 text-base font-normal italic text-content shadow-multiSelectDropdownShadow">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
