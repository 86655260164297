import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Spinner } from '@components';
import AuthLayoutImage from '@assets/images/auth_layout.webp';
import MobileLayout from '@layouts/mobile-layout/MobileLayout';
import { useLazyAppConfigQuery } from '@features/authentication/queries/AuthQuery';
import { setAppConfig } from '@layouts/main-header/userSlice';

import AccessGuard from '../access-guard/AccessGuard';

const AuthLayout = () => {
  const dispatch = useDispatch();

  const [getAppConfig] = useLazyAppConfigQuery();

  const getAppConfigData = async () => {
    const resp = await getAppConfig(null);
    if ('data' in resp) {
      dispatch(setAppConfig(resp.data));
    }
  };

  useEffect(() => {
    getAppConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccessGuard>
      <div className="z-100 hidden h-screen w-screen overflow-hidden mobile:flex">
        <MobileLayout />
      </div>
      <div className="flex h-screen items-center mobile:hidden">
        <section className="relative flex size-full items-center justify-center bg-content align-middle sm:w-1/3 md:w-1/2">
          <img
            src={AuthLayoutImage}
            alt="login-layout-pic"
            className="size-full object-contain"
          />
        </section>
        <section className="flex w-full items-center justify-center p-10 sm:w-2/3 md:w-1/2">
          <div className="m-6 flex w-105 flex-col justify-start">
            <Suspense
              fallback={
                <div className="flex size-full items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </section>
      </div>
    </AccessGuard>
  );
};

export default AuthLayout;
