/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckedIcon, LogoutModalIcon, UpgradeToOrgIcon } from '@icons';

import {
  Avatar,
  Button,
  ConfirmationModal,
  Icon,
  Modal,
  ProfileSettings
} from '@components';
import AvatarWithName from '@components/avatar-with-name/AvatarWithName';
import {
  AccountType,
  currentOrgAvatarColor,
  OrgStatus,
  UserFlow
} from '@constants/generic';
import { UserTypes } from '@constants/role';
import { AUTH, DASHBOARD, SUBSCRIPTIONS } from '@constants/routes';
import testId from '@constants/testId';
import { Event, sendMixpanelEvent } from '@core/analytics';
import {
  useCreateOrganizationMutation,
  useLazyAppConfigQuery,
  useLazyGetOrganizationListQuery,
  useLazyLogoutQuery,
  useOnboardOrganizationMutation
} from '@features/authentication/queries/AuthQuery';
import { resetAuthState } from '@features/authentication/slices/AuthSlice';
import {
  useLazyGetOrganizationConfigQuery,
  useLazyGetOrgFeatureFlagsConfigQuery
} from '@features/group-management/queries/GroupQuery';
import { resetGroupState } from '@features/group-management/slices/groupSlice';
import { resetMemberState } from '@features/member-management/slices/MemberSlice';
import { useLazyGetCurrentSubscriptionQuery } from '@features/subscription/queries';
import useNotify from '@hooks/notify';
import { OrgProfileContext } from '@layouts/constants';
import { ApiError } from '@types';
import { getRandomColor } from '@utils/generic';
import useOnClickOutside from '@utils/hooks';
import {
  clearAllLocalStorageItems,
  getLocalStorageItem,
  setMultipleLocalStorageItems
} from '@utils/storage';
import { Org } from 'types/organization';

import {
  resetUserState,
  setAccountType,
  setAppConfig,
  setCurrentOrg,
  setCurrentSubscription,
  setOrgConfig,
  setOrgFeatureFlags,
  setOrgListLoading
} from './userSlice';

const MainHeader = () => {
  const { createErrorAlert, createSuccessAlert } = useNotify();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname: currentPath } = useLocation();
  const ref = useRef(null);

  const [showUserSettings, setShowUserSettings] = useState<boolean>(false);
  const [isUpgradeToOrgModalOpen, setUpgradeToOrgModalOpen] =
    useState<boolean>(false);
  const [isSwitchAccountModalOpen, setSwitchAccountModalOpen] =
    useState<boolean>(false);
  const [orgList, setOrgList] = useState<Org[]>();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<Org | undefined>();

  const { currentUser } = useSelector((state: any) => state.user);

  const token = getLocalStorageItem('token');
  const userId = getLocalStorageItem('userId');
  const orgId = getLocalStorageItem('organizationId');
  const userType = getLocalStorageItem('userType');
  const currentOrg = orgList?.find((org: Org) => org.id === orgId);

  const [getOrgList, { isLoading: isOrgListLoading }] =
    useLazyGetOrganizationListQuery();

  const [getOrgConfig] = useLazyGetOrganizationConfigQuery();
  const [getAppConfig] = useLazyAppConfigQuery();
  const [getOrgFeatureFlags] = useLazyGetOrgFeatureFlagsConfigQuery();
  const [onboardOrganization] = useOnboardOrganizationMutation();

  useEffect(() => {
    dispatch(setOrgListLoading({ isOrgListLoading }));
  }, [isOrgListLoading]);

  const [logout, { isLoading: isLogoutLoading }] = useLazyLogoutQuery();
  const [createOrganization, { isLoading: isCreateOrgLoading }] =
    useCreateOrganizationMutation();
  const [getCurrentSubscription, { data: subscriptionData, isFetching }] =
    useLazyGetCurrentSubscriptionQuery();

  const onClickLogout = () => {
    setIsLogoutModalOpen(true);
    sendMixpanelEvent(Event.LOGOUT_CLICKED);
  };

  const onCancelLogout = () => {
    setIsLogoutModalOpen(false);
    sendMixpanelEvent(Event.LOGOUT_CANCEL_BUTTON_CLICKED);
  };

  const getCurrentOrg = () => orgList?.find((org: Org) => org.id === orgId);

  const isCurrentOrg = (org: Org | undefined) => org?.id === currentOrg?.id;

  const handleLogoutConfirm = async () => {
    sendMixpanelEvent(Event.LOGOUT_CONFIRM_BUTTON_CLICKED, {
      user_id: userId
    });
    const res = await logout(userId);
    if ('data' in res) {
      dispatch(resetGroupState());
      dispatch(resetUserState());
      dispatch(resetAuthState());
      dispatch(resetMemberState());
      clearAllLocalStorageItems();
      sendMixpanelEvent(Event.LOGOUT_SUCCESS, { user_id: userId });
      navigate(AUTH.LOGIN, { replace: true });
    } else if ('error' in res) {
      const error = res.error as ApiError;
      createErrorAlert(error.data?.message);
      sendMixpanelEvent(Event.LOGOUT_ERROR, {
        user_id: userId,
        error: error.data?.errorCode
      });
    }
  };

  const getOrgListData = async () => {
    const resp = await getOrgList(null, false);
    if ('data' in resp) {
      setOrgList(resp.data);
      setSelectedOrg(getCurrentOrg());
    } else if ('error' in resp) {
      createErrorAlert('Error in fetching organization list');
    }
  };

  const getOrgConfigData = async () => {
    const payload = {
      organizationId: currentOrg?.id || getLocalStorageItem('organizationId')
    };
    const resp = await getOrgConfig(payload);
    if ('data' in resp) {
      dispatch(setOrgConfig(resp.data));
    }
  };

  const getOrgFeatureFlagsData = async () => {
    const payload = {
      organizationId: currentOrg?.id || getLocalStorageItem('organizationId')
    };
    const resp = await getOrgFeatureFlags(payload);
    if ('data' in resp) {
      dispatch(setOrgFeatureFlags(resp.data));
    }
  };

  const getAppConfigData = async () => {
    const resp = await getAppConfig(null);
    if ('data' in resp) {
      dispatch(setAppConfig(resp.data));
    }
  };

  const handleGetCurrentSubscription = async () => {
    const res = await getCurrentSubscription({ organizationId: orgId });
    if ('data' in res) {
      dispatch(setCurrentSubscription(res.data));
    }
  };

  const { currentOrg: currentStoredOrg } = useSelector(
    (state: any) => state.user
  );

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setShowUserSettings(false);
  });

  const handleDropDownClick = () => {
    setShowUserSettings((prev) => !prev);
  };

  const createNewOrg = async () => {
    const response = await createOrganization(null);
    if ('data' in response) {
      setMultipleLocalStorageItems([
        { key: 'upgradedOrgId', value: response.data?.organisation_id },
        { key: 'upgradedBranchId', value: response.data?.branch_id },
        { key: 'userFlow', value: UserFlow.UPGRADE_TO_ORG }
      ]);
      navigate(AUTH.SELECT_ACCOUNT);
    } else if ('error' in response) {
      const error = response.error as ApiError;
      createErrorAlert(error.data?.message);
    }
    setUpgradeToOrgModalOpen(false);
  };

  const onClickUpgradeToOrg = () => {
    setUpgradeToOrgModalOpen(true);
    sendMixpanelEvent(Event.UPGRADE_TO_ORGANIZATION_POPUP_VIEWED, {
      individual_organization_name: currentUser?.name || ''
    });
  };

  const upgradeIndividualLiteToOrgLite = async () => {
    const requestBody = {
      organizationId: orgId,
      body: {
        branch_name: currentOrg?.default_branch_name.trim(),
        organisation_name: currentOrg?.name.trim(),
        branch_id: currentOrg?.default_branch_id,
        is_lite: true
      }
    };
    const response = await onboardOrganization(requestBody);
    if ('data' in response) {
      createSuccessAlert('Updated to organization successfully');
      setUpgradeToOrgModalOpen(true);
      window.location.reload();
    } else {
      const error = response.error as ApiError;
      createErrorAlert(
        error?.data?.message || 'Error in upgrading to organization'
      );
    }
  };

  const onConfirmUpgradeToOrg = () => {
    sendMixpanelEvent(Event.UPGRADE_TO_ORGANIZATION_CONFIRM_BUTTON_CLICKED, {
      individual_organization_name: currentUser?.name || ''
    });
    if (
      currentOrg?.status === OrgStatus.KYC_PENDING &&
      currentOrg?.type === AccountType.INDIVIDUAL &&
      !currentOrg?.is_upgrade_requested &&
      !currentOrg?.is_kyc_deferred
    ) {
      upgradeIndividualLiteToOrgLite();
    } else createNewOrg();
  };

  const onCancelUpgradeToOrg = () => {
    setUpgradeToOrgModalOpen(false);
    sendMixpanelEvent(Event.UPGRADE_TO_ORGANIZATION_CANCEL_BUTTON_CLICKED, {
      individual_organization_name: currentUser?.name || ''
    });
  };

  const onClickSwitchAccount = () => {
    setSwitchAccountModalOpen(true);
    sendMixpanelEvent(Event.SWITCH_ACCOUNT_CLICKED);
  };

  const onCloseSwitchAccountModal = () => {
    setSelectedOrg(currentOrg);
    setSwitchAccountModalOpen(false);
    sendMixpanelEvent(Event.SWITCH_ACCOUNT_CANCEL_BUTTON_CLICKED);
  };

  const onSelectOrg = (organization: Org) => {
    setSelectedOrg(organization);
  };

  const onSwitchOrg = () => {
    if (selectedOrg) {
      if (isCurrentOrg(selectedOrg)) return;
      const {
        id,
        default_branch_id: branchId,
        default_branch_name: branchName
      } = selectedOrg;
      setMultipleLocalStorageItems([
        { key: 'organizationId', value: id },
        { key: 'branchId', value: branchId },
        { key: 'branchName', value: branchName }
      ]);
      navigate(DASHBOARD);
      setSwitchAccountModalOpen(false);
      sendMixpanelEvent(Event.SWITCH_ACCOUNT_CONTINUE_BUTTON_CLICKED, {
        switched_to: selectedOrg.name
      });
    }
  };

  const isIndividualLite = (org: Org) =>
    org?.status === OrgStatus.KYC_PENDING &&
    !org?.has_plan &&
    !org?.is_kyc_deferred &&
    org?.type === AccountType.INDIVIDUAL;

  useEffect(() => {
    if (currentOrg?.id === currentStoredOrg?.id) return;
    if (!orgId) {
      navigate(AUTH.ORG_SELECTION);
      return;
    }
    dispatch(setCurrentOrg(currentOrg));
    dispatch(setAccountType({ type: currentOrg?.type }));
  }, [currentOrg]);

  useEffect(() => {
    const latestOrg = currentStoredOrg || currentOrg;
    if (
      (latestOrg?.status === OrgStatus.APPROVED && !latestOrg.has_plan) ||
      isIndividualLite(latestOrg)
    ) {
      if (currentPath !== SUBSCRIPTIONS.CHOOSE_PLAN) {
        navigate(SUBSCRIPTIONS.CHOOSE_PLAN);
      }
    }
  }, [currentPath, currentStoredOrg, currentOrg, navigate]);

  useEffect(() => {
    if (orgList && orgList.length <= 0 && userType !== UserTypes.ADMIN)
      navigate(AUTH.SELECT_ACCOUNT);
  }, [orgList]);

  useEffect(() => {
    if (!token) return;
    getAppConfigData();
    getOrgListData();
    getOrgConfigData();
    getOrgFeatureFlagsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoredOrg]);

  const context = useMemo(() => {
    if (
      (currentOrg?.status === OrgStatus.APPROVED && !currentOrg.has_plan) ||
      currentOrg?.status === OrgStatus.VERIFICATION_PENDING ||
      currentOrg?.status === OrgStatus.VERIFICATION_SUBMITTED
    ) {
      return '';
    }

    if (
      currentUser?.is_user_onboarded &&
      currentOrg?.type === AccountType.INDIVIDUAL &&
      !currentOrg?.is_upgrade_requested &&
      (currentOrg?.status === OrgStatus.APPROVED ||
        currentOrg?.status === OrgStatus.KYC_PENDING) &&
      !isFetching &&
      subscriptionData?.status !== 'expired'
    )
      return OrgProfileContext.UPGRADE_TO_ORG;
    // Add an Org flow commented out for first phase
    // if (
    //   currentOrg?.role === OrgRoleType.OWNER &&
    //   currentOrg?.type === AccountType.ORGANIZATION
    // )
    //   return OrgProfileContext.CREATE_ORG;
    if (!currentUser?.is_user_onboarded)
      return OrgProfileContext.CREATE_ACCOUNT;
    return '';
  }, [currentUser, currentOrg, isFetching, subscriptionData]);

  useEffect(() => {
    if (orgId) handleGetCurrentSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return (
    <div className="fixed left-0 top-0 z-30 h-auto w-full bg-theme text-content shadow-sm">
      <div className="flex h-16 w-full items-center justify-end border-b px-6 shadow-sm">
        <div className="flex gap-2">
          {/* <div className="flex items-center justify-center text-base font-normal text-content">
            {currentUser?.name}
          </div> */}

          <div
            data-testid={testId.header.profileButton}
            className="relative flex cursor-pointer items-center justify-center gap-2"
            role="presentation"
            onClick={handleDropDownClick}
            ref={ref}
          >
            <Avatar
              imageId={testId.header.profileButtonIcon}
              name={currentUser?.name || ''}
              className="size-10 rounded-full text-sm text-white"
              bgColor={currentOrgAvatarColor}
            />
            <Icon
              name="down"
              size="24"
              className={showUserSettings ? 'rotate-180' : 'rotate-0'}
            />
            {showUserSettings && (
              <ProfileSettings
                orgList={orgList || []}
                context={context}
                onClickUpgradeToOrg={onClickUpgradeToOrg}
                onClickSwitchAccount={onClickSwitchAccount}
                onClickLogout={onClickLogout}
              />
            )}
          </div>
        </div>
      </div>
      {isUpgradeToOrgModalOpen && (
        <ConfirmationModal
          buttonIds={{
            confirmButton: testId.header.upgradeToOrgPopup.confirm,
            cancelButton: testId.header.upgradeToOrgPopup.cancel
          }}
          isOpen
          title="Upgrade to Organization ?"
          message="Once your request to upgrade to organization is accepted, you will no longer have access to your individual account."
          ModalIcon={UpgradeToOrgIcon}
          handleConfirm={onConfirmUpgradeToOrg}
          handleCancel={onCancelUpgradeToOrg}
          isConfirmButtonLoading={isCreateOrgLoading}
          className="max-w-95"
        />
      )}
      {isLogoutModalOpen && (
        <ConfirmationModal
          buttonIds={{
            confirmButton: testId.header.logoutPopup.confirm,
            cancelButton: testId.header.logoutPopup.cancel
          }}
          isOpen={isLogoutModalOpen}
          ModalIcon={LogoutModalIcon}
          title="Logging Out?"
          message="Are you sure you want to log out?"
          confirmButtonStyle="text-primary"
          handleConfirm={handleLogoutConfirm}
          handleCancel={onCancelLogout}
          className="w-78"
          isConfirmButtonLoading={isLogoutLoading}
        />
      )}

      {isSwitchAccountModalOpen && (
        <Modal
          isOpen={isSwitchAccountModalOpen}
          onCancel={onCloseSwitchAccountModal}
          isCloseIconRequired
          className="h-fit w-125 p-6"
        >
          <div className="mb-4 text-xl font-bold text-content">
            Switch Account
          </div>
          <div className="max-h-48 overflow-auto">
            {orgList?.map((org, index) => {
              const isSelectedOrg = org.id === selectedOrg?.id;
              return (
                <div
                  key={org.id}
                  className="flex cursor-pointer items-center justify-between p-3 hover:bg-grey20"
                  role="presentation"
                  onClick={() => onSelectOrg(org)}
                >
                  <AvatarWithName
                    name={org.name}
                    subText={org.role_name || ''}
                    imageUrl={org.image_url}
                    bgColor={
                      isCurrentOrg(org)
                        ? currentOrgAvatarColor
                        : getRandomColor(index)
                    }
                    avatarClass={isCurrentOrg(org) ? 'text-white' : ''}
                  />
                  {isSelectedOrg && <CheckedIcon className="h-3 w-4" />}
                </div>
              );
            })}
          </div>
          <div className="mt-4 flex w-full justify-end">
            <Button
              id={testId.header.switchAccountModal.cancel}
              label="Cancel"
              variant="secondary"
              handleClick={onCloseSwitchAccountModal}
            />
            <Button
              id={testId.header.switchAccountModal.continue}
              label="Continue"
              handleClick={onSwitchOrg}
              className="ml-4"
              disabled={isCurrentOrg(selectedOrg)}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MainHeader;
