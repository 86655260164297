import Papa from 'papaparse';

const XLSX = await import('xlsx');

export const bytesToMB = (bytes = 0) => {
  const sizeInMB = (bytes / (1024 * 1024)).toFixed(2);
  if (Number(sizeInMB) >= 1) {
    const formattedSizeInMB = Number.parseFloat(sizeInMB);
    return `${formattedSizeInMB} MB`;
  }
  const sizeInKB = (bytes / 1024).toFixed(2);
  const formattedSizeInKB = Number.parseFloat(sizeInKB);
  return `${formattedSizeInKB} KB`;
};

export const parseXLSXFile = (
  fileToParse: File
): Promise<Papa.ParseResult<string[]>> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = (event.target as FileReader).result; // Type assertion here
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert worksheet to JSON with raw data
      const jsonData: unknown[][] = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: true
      });

      const headers = jsonData[0] as string[];
      const rows = jsonData.slice(1).map((row: any[]) =>
        row.map((cell, index) => {
          if (
            typeof cell === 'number' &&
            (headers[index] === 'primaryNumber' ||
              headers[index] === 'altNumber')
          ) {
            // Convert large numbers to strings
            return cell.toString();
          }
          if (
            typeof cell === 'number' &&
            headers[index] === 'dueDate' &&
            XLSX.SSF.format('yyyy-mm-dd', cell) !== cell.toString()
          ) {
            // Convert date codes to date strings
            return XLSX.SSF.format('yyyy-mm-dd', cell);
          }
          return cell;
        })
      );

      // Combine headers and rows into a single array
      const combinedData = [headers, ...rows];
      const csvData = Papa.unparse(combinedData);

      Papa.parse(csvData, {
        header: true,
        dynamicTyping: true,
        complete: (result: any) => {
          // Filter out empty rows
          const filteredData = result.data.filter((row: any) =>
            Object.values(row).some((cell) => cell !== null && cell !== '')
          );
          result.data = filteredData;
          resolve(result);
        },
        error: (error: any) => reject(error)
      });
    };
    reader.readAsBinaryString(fileToParse);
  });

export const parseCSVFile = (
  fileToParse: File
): Promise<Papa.ParseResult<string[]>> =>
  new Promise((resolve, reject) => {
    Papa.parse(fileToParse, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result: any) => resolve(result),
      error: (error: any) => reject(error)
    });
  });
