import { memo } from 'react';

import { AvatarProps } from '@types';
import { getFirstLetters, getRandomColor } from '@utils/generic';

const Avatar = ({
  className = '',
  name,
  imageUrl,
  bgColor,
  imageId
}: AvatarProps) => (
  <div
    className={`flex items-center justify-center rounded-full border border-grey30 font-medium text-content ${className}`}
    style={{ backgroundColor: bgColor || getRandomColor() }}
  >
    {imageUrl ? (
      <img
        data-testid={imageId}
        className={`size-full rounded-full object-cover ${className}`}
        src={imageUrl}
        alt=""
      />
    ) : (
      <>{getFirstLetters(name)}</>
    )}
  </div>
);

export default memo(Avatar);
