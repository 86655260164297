import testId from '@constants/testId';

import { PageTitleProps } from './type';
import Button from '../button/Button';

const PageTitle = (props: PageTitleProps) => {
  const {
    title,
    primaryButtonLabel,
    primaryButtonIcon,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    secondaryButtonLabel,
    primaryButtonIconStyle,
    primaryButtonStroke,
    className,
    isPrimaryButtonDisabled,
    isPrimaryButtonLoading,
    primaryButtonStyle = '',
    secondaryButtonStyle = '',
    buttonIds
  } = props;

  const isButtonGroup = primaryButtonLabel && secondaryButtonLabel;

  return (
    <div className={`flex items-center justify-between ${className || ''}`}>
      <h1
        className="text-2xl font-bold text-content"
        data-testid={testId.pageTitle}
      >
        {title}
      </h1>
      <div className="flex items-center gap-4">
        {secondaryButtonLabel && (
          <Button
            data-testid={buttonIds?.secondaryButton}
            label={secondaryButtonLabel}
            handleClick={onSecondaryButtonClick}
            variant="secondary"
            size="medium"
            className={`${isButtonGroup ? 'w-20' : ''} ${secondaryButtonStyle}`}
          />
        )}
        {primaryButtonLabel && (
          <Button
            data-testid={buttonIds?.primaryButton}
            label={primaryButtonLabel}
            handleClick={onPrimaryButtonClick}
            variant="primary"
            size="medium"
            leftIconName={primaryButtonIcon}
            leftIconStyle={primaryButtonIconStyle}
            leftIconStroke={primaryButtonStroke || ''}
            disabled={isPrimaryButtonDisabled || false}
            loading={isPrimaryButtonLoading || false}
            className={`${isButtonGroup ? 'w-20' : ''} ${primaryButtonStyle}`}
          />
        )}
      </div>
    </div>
  );
};

export default PageTitle;
