import { RadioCardType } from '../../types/radio-card';

export const invitedMembersTableColumn = [
  {
    Header: 'Name',
    accessor: 'name',
    width: '17%'
  },
  { Header: 'Role', accessor: 'role', width: '15%' },
  { Header: 'Branch', accessor: 'branch', width: '25%' },
  { Header: 'Group', accessor: 'group', width: '22%' },
  { Header: 'User Status', accessor: 'status', width: '10%' },
  { Header: 'Actions', accessor: 'actions', width: '20%' }
];

export const userRolesTableColumn = [
  {
    Header: 'Name',
    accessor: 'name',
    width: '20%'
  },
  { Header: 'Role', accessor: 'role', width: '20%' },
  { Header: 'Branch', accessor: 'branch', width: '25%' },
  { Header: 'Group', accessor: 'group', width: '25%' },
  { Header: 'Actions', accessor: 'actions' }
];

export enum RoleType {
  ORGANISATION_ADMIN = 'organisation_admin',
  BRANCH_ADMIN = 'branch_admin',
  GROUP_ADMIN = 'group_admin',
  FINANCE_ADMIN = 'finance',
  ATTENDANCE_MANAGER = 'attendance_manager'
}

export const roleDisplayNameMap: { [key: string]: string } = {
  [RoleType.ORGANISATION_ADMIN]: 'Organization Admin',
  [RoleType.BRANCH_ADMIN]: 'Branch Admin',
  [RoleType.GROUP_ADMIN]: 'Group Admin',
  [RoleType.FINANCE_ADMIN]: 'Finance',
  [RoleType.ATTENDANCE_MANAGER]: 'Attendance Manager'
};

export const RoleDetails = {
  [RadioCardType.ORGANISATION_ADMIN]: {
    title: roleDisplayNameMap[RoleType.ORGANISATION_ADMIN],
    description:
      'All privileges except deleting other organization admins and owner of the organization'
  },
  [RadioCardType.BRANCH_ADMIN]: {
    title: roleDisplayNameMap[RoleType.BRANCH_ADMIN],
    description:
      'Manage one or more branches and oversee actions within these branches.'
  },
  [RadioCardType.GROUP_ADMIN]: {
    title: roleDisplayNameMap[RoleType.GROUP_ADMIN],
    description:
      'Manage the addition and deletion of members in assigned groups, and record or initate different aspects of transactions.'
  },
  [RadioCardType.FINANCE_ADMIN]: {
    title: roleDisplayNameMap[RoleType.FINANCE_ADMIN],
    description:
      'View transactions, download report and view details of branches given access to.'
  },
  [RadioCardType.ATTENDANCE_MANAGER]: {
    title: roleDisplayNameMap[RoleType.ATTENDANCE_MANAGER],
    description:
      'Attendance manager can mark and view attendance for one or more groups they have been granted access.'
  }
};

export const userStatusStyle = {
  pending: 'bg-yellowLite text-yellow',
  rejected: 'bg-errorLite1 text-errorLite2'
};

type RoleDropdownOptions = {
  [key: string]: Array<{
    id: number;
    label: string;
    value: string;
  }>;
};

export const getRoleDropdownOptions = (
  includeAttendanceManager: boolean
): RoleDropdownOptions => {
  const baseOptions = {
    owner: [
      {
        id: 1,
        label: roleDisplayNameMap[RoleType.ORGANISATION_ADMIN],
        value: RoleType.ORGANISATION_ADMIN
      },
      {
        id: 2,
        label: roleDisplayNameMap[RoleType.BRANCH_ADMIN],
        value: RoleType.BRANCH_ADMIN
      },
      {
        id: 3,
        label: roleDisplayNameMap[RoleType.GROUP_ADMIN],
        value: RoleType.GROUP_ADMIN
      },
      {
        id: 4,
        label: roleDisplayNameMap[RoleType.FINANCE_ADMIN],
        value: RoleType.FINANCE_ADMIN
      }
    ],
    organisation_admin: [
      {
        id: 1,
        label: roleDisplayNameMap[RoleType.BRANCH_ADMIN],
        value: RoleType.BRANCH_ADMIN
      },
      {
        id: 2,
        label: roleDisplayNameMap[RoleType.GROUP_ADMIN],
        value: RoleType.GROUP_ADMIN
      },
      {
        id: 3,
        label: roleDisplayNameMap[RoleType.FINANCE_ADMIN],
        value: RoleType.FINANCE_ADMIN
      }
    ],
    branch_admin: [
      {
        id: 1,
        label: roleDisplayNameMap[RoleType.GROUP_ADMIN],
        value: RoleType.GROUP_ADMIN
      },
      {
        id: 2,
        label: roleDisplayNameMap[RoleType.FINANCE_ADMIN],
        value: RoleType.FINANCE_ADMIN
      }
    ]
  };

  // Function to add "Attendance Manager" role dynamically
  const addAttendanceManager = (
    options: Array<{ id: number; label: string; value: string }>
  ) => {
    const nextId = options.length + 1; // Calculate the next available ID
    options.push({
      id: nextId,
      label: roleDisplayNameMap[RoleType.ATTENDANCE_MANAGER],
      value: RoleType.ATTENDANCE_MANAGER
    });
  };

  // Add "Attendance Manager" role conditionally
  if (includeAttendanceManager) {
    addAttendanceManager(baseOptions.owner);
    addAttendanceManager(baseOptions.organisation_admin);
    addAttendanceManager(baseOptions.branch_admin);
  }

  return baseOptions;
};

export const roleIconMap: { [key: string]: string } = {
  [roleDisplayNameMap[RoleType.ORGANISATION_ADMIN]]: 'organization-admin-green',
  [roleDisplayNameMap[RoleType.BRANCH_ADMIN]]: 'branch-admin-green',
  [roleDisplayNameMap[RoleType.GROUP_ADMIN]]: 'group-admin-green',
  [roleDisplayNameMap[RoleType.FINANCE_ADMIN]]: 'finance-admin-green',
  [roleDisplayNameMap[RoleType.ATTENDANCE_MANAGER]]: 'attendance-manager-green'
};
