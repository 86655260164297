import { useEffect, useRef, useState } from 'react';
import { Divider, Skeleton } from '@mui/material';

import useOnClickOutside from '@utils/hooks';
import { DropdownOption, DropdownProps } from 'types/dropdown';

import Button from '../button/Button';
import Icon from '../icon/Icon';

import theme from '../../../themes/colors';

const Dropdown = (props: DropdownProps) => {
  const {
    filterLabel,
    dropdownId,
    currentFilter,
    filterOptions,
    onSelectFilter,
    className,
    optionsStyle,
    labelStyle,
    isLoading,
    placeholder,
    loadingStyles,
    optionsCount,
    placeholderStyle,
    dropdownHeaderStyle,
    listId,
    disabled
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const ref = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((isOpen) => !isOpen);
  };

  const onClickFilter = (option: DropdownOption) => {
    if (option.value !== currentFilter && !option.isDisabled) {
      onSelectFilter(option.value);
      toggleDropdown();
    }
  };

  const selectedFilter: DropdownOption | undefined = filterOptions?.find(
    (option) => option.value === currentFilter
  );

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    if (isDropdownOpen) {
      const element = document.getElementById(`${listId}-dropdown-list`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }, [isDropdownOpen, listId]);

  if (isLoading)
    return (
      <div className={loadingStyles || 'w-full'}>
        <Skeleton
          height={53}
          key={Math.random()}
          animation="wave"
          variant="rounded"
        />
      </div>
    );

  return (
    <div className={`relative ${className || ''}`} ref={ref}>
      <div className={`flex items-center gap-2 ${dropdownHeaderStyle || ''}`}>
        {filterLabel && (
          <span className="text-base font-medium text-grey">{filterLabel}</span>
        )}
        <Button
          data-testid={dropdownId}
          variant="tertiary"
          handleClick={toggleDropdown}
          size="medium"
          label={
            (selectedFilter?.element ?? selectedFilter?.label) || placeholder
          }
          labelCount={optionsCount}
          rightIconName="down-icon-dark"
          className={`min-w-166 justify-between border ${className}`}
          labelStyle={
            placeholder && !selectedFilter?.label
              ? `!text-base text-grey !font-normal ${placeholderStyle || ''}`
              : `!font-normal ${labelStyle || ''}`
          }
          rightIconStyle={isDropdownOpen ? 'rotate-180' : ''}
          disabled={disabled}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={`absolute z-20 mt-1 w-55 origin-top-right rounded-md border border-grey40 bg-theme px-2 shadow-sm ${
            optionsStyle || 'right-0'
          }`}
          id={`${listId}-dropdown-list`}
        >
          {filterOptions?.map((option, index) => (
            <>
              <div
                id={`${filterLabel || 'dropdown'}_${option.value}`}
                key={option?.id}
                className={`my-1 flex items-center justify-between rounded-lg py-2 pl-3 pr-4 text-sm first:pt-3 last:pb-3 ${
                  option.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                } ${
                  option.value === selectedFilter?.value
                    ? 'bg-blue4'
                    : 'hover:bg-gray-100'
                }`}
                role="presentation"
                onClick={() => onClickFilter(option)}
              >
                {option.element ?? option.label}
                {option.value === selectedFilter?.value && (
                  <Icon
                    name="check-mark"
                    className="ml-2 !h-4 !min-h-4 !w-4 !min-w-4"
                    stroke={theme.primary}
                  />
                )}
              </div>
              {index + 1 !== filterOptions.length && (
                <Divider className="border-grey40" />
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
