import { PaymentCollectionType } from '@features/group-management/constants';
import { ApiError, FeeConfigurationType, MemberListType } from '@types';

export interface Member {
  created_at: string;
  fee_amount: string;
  group_member_id?: number;
  is_active: boolean;
  is_payment_link_sent: boolean;
  member_id: string;
  member_image_url?: string;
  name: string;
  next_due_at: string;
  payment_due?: string;
  phone: string;
  secondary_mobile: string;
  secondary_mobile_number?: string;
  amount?: string;
  primary_mobile?: string;
  primary_mobile_number?: string;
  fee_amount_configuration?: FeeConfigurationType[];
  id: string;
  primary_country_code: string;
  secondary_country_code: string;
  email: string;
}

export interface MemberAttendanceStatus {
  id: number;
  group_member_id: number;
  status: string;
  name: string;
}

export interface MemberAttendanceCount {
  present_count: number;
  absent_count: number;
  late_count: number;
}

export type UpdatemMemberApiResponse = {
  id: string;
  name: string;
  is_active: boolean;
  created_at: string;
  group_name: string;
  primary_mobile: string;
  secondary_mobile: string;
  amount: string;
  next_due_at: string;
  is_payment_link_sent: boolean;
  is_split_payment: boolean;
};

export interface EditMemberProps {
  isOpen: boolean;
  handleCancel: () => void;
  handleMemberUpdateSuccess: (member: MemberListType) => void;
  handleMemberUpdateError: (error: ApiError, member: MemberListType) => void;
  member: Member;
  paymentCollectionData: {
    type: PaymentCollectionType | '';
    intervalFrequency: number;
  };
  getApiParams: () => {
    organizationId: string;
    branchId: string;
    groupId: string;
  };
  isFeeConfigured?: boolean;
  feeConfigurationDetails?: FeeConfigurationType[];
  groupFrequency: string;
  groupIntervalFrequency: number;
  groupStartDate?: string;
}

export type AddMemberInput = {
  altNumber: string | null;
  amount: string | null;
  dueDate: string | null;
  name: string | null;
  primaryNumber: string | null;
  primaryNumberCode: string | null;
  altNumberCode: string | null;
  email: string | null;
  feeCategoryData?: {
    id: number | string;
    label: number | string;
    value: number | string;
  }[];
  amountPaid: string | null;
  admissionNumber: string | null;
  admissionDate: string | null;
  dateOfBirth: string | null;
  guardianName: string | null;
  age: string | null;
  bloodGroup?: BloodGroups | null;
  isInsured?: string | null;
};

export enum BloodGroups {
  A_POSITIVE = 'A+',
  A_NEGATIVE = 'A-',
  B_POSITIVE = 'B+',
  B_NEGATIVE = 'B-',
  AB_POSITIVE = 'AB+',
  AB_NEGATIVE = 'AB-',
  O_POSITIVE = 'O+',
  O_NEGATIVE = 'O-'
}
