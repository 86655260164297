import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useNotify from '@hooks/notify';
import Head from '@layouts/head/Head';
import { OrgStatus } from '@constants/generic';
import { ApiError, FileUploadType } from '@types';
import { getLocalStorageItem } from '@utils/storage';
import { useImageUploadMutation } from '@features/group-management/queries/GroupQuery';
import {
  useGetReUploadListQuery,
  useLazyGetOrganizationListQuery
} from '@features/authentication/queries/AuthQuery';
import { Org } from 'types/organization';
import { setAccountType, setCurrentOrg } from '@layouts/main-header/userSlice';
import { sendMixpanelEvent, trackPageView, Event } from '@core/analytics';
import { DOCUMENTS } from '@features/admin/constants';
import { BackgroundImage } from '@images';

import PendingVerification from '../pending-verification/PendingVerification';
import FileUpload from '../file-upload/FileUpload';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import SuccessfullCard from './successfull-card/SuccessfullCard';
import Spinner from '../spinner/Spinner';

const backgroundStyle = {
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};

const ReuploadDocument = () => {
  const dispatch = useDispatch();
  const orgId = getLocalStorageItem('organizationId');
  const { data: docList, isLoading } = useGetReUploadListQuery(orgId, {
    refetchOnMountOrArgChange: true
  });
  const [getOrgList] = useLazyGetOrganizationListQuery();

  const [uploadImage] = useImageUploadMutation();
  const { createErrorAlert, createSuccessAlert } = useNotify();
  const [isUploadDone, setIsUploadDone] = useState<boolean>(false);
  const [documents, setDocuments] = useState<
    {
      id: string;
      label: string;
      reason: string;
      file: null | File;
    }[]
  >([]);
  const [successfullUploads, setSuccessfullUploads] = useState<
    { id: string; label: string; fileName: string }[]
  >([]);

  const getOrgListData = async () => {
    const resp = await getOrgList(null);
    if ('data' in resp) {
      const currentOrg = resp.data?.find((org: Org) => org.id === orgId);
      dispatch(setCurrentOrg(currentOrg));
      dispatch(setAccountType({ type: currentOrg?.type }));
    }
    if ('error' in resp) {
      createErrorAlert('Error in fetching organization list');
    }
  };

  const handleSubmit = async () => {
    const organizationId = localStorage.getItem('organizationId') || '';
    let successfulUploadCount = 0;

    documents.map(async (doc) => {
      const imageData = new FormData();
      if (doc.file) imageData.append('file', doc.file);
      imageData.append('type', doc.id);
      imageData.append('id', organizationId);
      const imageUploadResponse = await uploadImage(imageData);
      if ('data' in imageUploadResponse) {
        successfulUploadCount += 1;
        sendMixpanelEvent(Event.DOCUMENT_REUPLOAD_SUCCESS, {
          document_type: doc.label,
          file_name: doc.file?.name
        });
      } else if ('error' in imageUploadResponse) {
        const error = imageUploadResponse.error as ApiError;
        createErrorAlert(`Error while uploading ${doc.label}`);
        sendMixpanelEvent(Event.DOCUMENT_REUPLOAD_ERROR, {
          document_type: doc.label,
          file_name: doc?.file?.name,
          error: error.data?.errorCode
        });
      }
      if (successfulUploadCount === documents.length) {
        setIsUploadDone(true);
        createSuccessAlert('Documents uploaded successfully');
      }
    });
  };

  const handleUploadDocument = (id: string, doc = null) => {
    const index = documents.findIndex((item) => item.id === id);
    if (index !== -1) {
      const tempDoc = [...documents];
      tempDoc[index].file = doc;
      setDocuments(tempDoc);
    }
  };

  const setFilesToUpload = () => {
    if (docList && docList.length > 0) {
      const updatedFilesToUpload: any = [];
      const successfullDocList: any = [];
      docList.forEach((doc: any) => {
        if (doc.status === OrgStatus.REJECTED) {
          switch (doc.type) {
            case DOCUMENTS.BUSINESS_LICENSE:
              updatedFilesToUpload.push({
                id: 'business_license',
                label: doc.label,
                reason: doc.reason,
                file: null
              });
              break;
            case DOCUMENTS.PAN_CARD:
              updatedFilesToUpload.push({
                id: 'pan',
                label: doc.label,
                reason: doc.reason,
                file: null
              });
              break;
            case DOCUMENTS.GST_CERTIFICATE:
              updatedFilesToUpload.push({
                id: 'gst_certificate',
                label: doc.label,
                reason: doc.reason,
                file: null
              });
              break;
            default:
              break;
          }
        } else if (doc.status === OrgStatus.APPROVED) {
          switch (doc.type) {
            case DOCUMENTS.BUSINESS_LICENSE:
              successfullDocList.push({
                id: 'business_license',
                label: doc.label,
                fileName: doc.file_name
              });
              break;
            case DOCUMENTS.PAN_CARD:
              successfullDocList.push({
                id: 'pan',
                label: doc.label,
                fileName: doc.file_name
              });
              break;
            case DOCUMENTS.GST_CERTIFICATE:
              successfullDocList.push({
                id: 'gst_certificate',
                label: doc.label,
                fileName: doc.file_name
              });
              break;
            default:
              break;
          }
        }
      });
      setDocuments(updatedFilesToUpload);
      setSuccessfullUploads(successfullDocList);
    }
  };

  useEffect(() => {
    setFilesToUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docList]);

  useEffect(() => {
    if (isUploadDone) {
      getOrgListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadDone]);

  const { currentOrg } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (currentOrg?.id) {
      trackPageView('Document Reupload', {
        organization_name: currentOrg.name
      });
    }
  }, [currentOrg]);

  return isUploadDone ? (
    <>
      <Head title="Pending Verification" />
      <PendingVerification />
    </>
  ) : (
    <div
      className="flex h-screen w-screen items-center justify-center py-10"
      style={backgroundStyle}
    >
      <div className="flex h-auto w-126.5 flex-col items-center justify-center rounded-2xl bg-theme p-8">
        <div className="flex flex-col items-center justify-center">
          <Icon name="re-upload" size="none" />
          <div className="mt-6 text-center text-2xl font-medium">
            Oops! Issue with uploaded documents.
          </div>

          <div className="mt-2 text-base font-normal text-grey">
            Please review and try uploading again.
          </div>
          <div className="my-4 flex w-full flex-col gap-4 rounded-2xl bg-white p-4 shadow-uploadContainerShadow">
            {isLoading && <Spinner />}
            {successfullUploads.length >= 1 &&
              successfullUploads.map((item) => (
                <div
                  key={item.id}
                  className="border-b border-b-grey50 pb-4 last:border-b-0 last:pb-0"
                >
                  <SuccessfullCard doc={item} />
                </div>
              ))}
            {documents.length >= 1 &&
              documents.map((item) => (
                <div
                  key={item.id}
                  className="border-b border-b-grey50 pb-4 last:border-b-0 last:pb-0"
                >
                  <FileUpload
                    label={item?.label}
                    handleAdd={(file) => handleUploadDocument(item.id, file)}
                    handleRemove={() => handleUploadDocument(item.id)}
                    type={FileUploadType.RE_UPLOAD}
                    reason={item?.reason}
                  />
                </div>
              ))}
          </div>
          <Button
            label="Continue"
            handleClick={handleSubmit}
            variant="primary"
            className="w-full"
            disabled={documents.some((doc) => doc.file === null)}
          />
        </div>
      </div>
    </div>
  );
};
export default ReuploadDocument;
