import {
  BRANCHES,
  ADMIN,
  TEAM_MANAGEMENT,
  HELP,
  DASHBOARD,
  TRANSACTIONS,
  SUBSCRIPTIONS,
  REPORTS,
  ORG_SETTINGS,
  QUICK_COLLECT,
  MEMBERS,
  GROUPS
} from '@constants/routes';
import {
  GroupsIcon,
  TransactionsIcon,
  QuickCollectIcon,
  ReportsIcon,
  SubscriptionIcon,
  DashboardIcon,
  BranchesIcon,
  TeamManagementIcon,
  OrgSettingsIcon,
  AdminIcon,
  HelpIcon,
  MembersIcon,
  CustomerListIcon
} from '@images';

import UserPermissionTypes from './permissions';

export const groupsTabPathPattern =
  /^\/groups(\/add|\/grp_[A-Za-z0-9]*(?:\/(?:edit|add-members))?)?$/;

const INDIVIDUAL_MENU_ITEMS = [
  {
    id: 'groups',
    Icon: GroupsIcon,
    label: 'Groups',
    route: GROUPS.HOME,
    permissions: [UserPermissionTypes.GROUP_VIEW]
  },
  {
    id: 'transactions',
    Icon: TransactionsIcon,
    label: 'Transactions',
    route: TRANSACTIONS.TRANSACTIONS,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_VIEW]
  },
  {
    id: 'quick-collect',
    Icon: QuickCollectIcon,
    label: 'Quick Collect',
    route: QUICK_COLLECT.CREATE_LINK,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_CREATE]
  },
  {
    id: 'report',
    Icon: ReportsIcon,
    label: 'Reports',
    route: REPORTS,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_GENERATE_GST_REPORT]
  },
  {
    id: 'subscriptions',
    Icon: SubscriptionIcon,
    label: 'Plan & Billing',
    route: SUBSCRIPTIONS.PLAN_DETAILS,
    permissions: [UserPermissionTypes.SUBSCRIPTION_VIEW]
  },
  {
    id: 'org-settings',
    Icon: OrgSettingsIcon,
    label: 'Configurations',
    route: ORG_SETTINGS,
    permissions: [UserPermissionTypes.SUBSCRIPTION_VIEW]
  }
];

const ORG_MENU_ITEMS = [
  {
    id: 'dashboard',
    Icon: DashboardIcon,
    label: 'Dashboard',
    route: DASHBOARD,
    permissions: [UserPermissionTypes.DASHBOARD_VIEW]
  },
  {
    id: 'branches',
    Icon: BranchesIcon,
    label: 'Branches',
    route: BRANCHES.BRANCHES,
    permissions: [UserPermissionTypes.BRANCH_VIEW]
  },
  {
    id: 'groups',
    Icon: GroupsIcon,
    label: 'Groups',
    route: GROUPS.HOME,
    permissions: [UserPermissionTypes.GROUP_VIEW]
  },
  {
    id: 'members',
    Icon: MembersIcon,
    label: 'Members',
    route: MEMBERS.MEMBERS,
    permissions: [UserPermissionTypes.BRANCH_MEMBER_VIEW]
  },
  {
    id: 'team-management',
    Icon: TeamManagementIcon,
    label: 'Team Management',
    route: TEAM_MANAGEMENT.TEAM_MANAGEMENT,
    permissions: [
      UserPermissionTypes.ORG_ADMIN_VIEW,
      UserPermissionTypes.OWNER_VIEW,
      UserPermissionTypes.BRANCH_ADMIN_VIEW,
      UserPermissionTypes.GROUP_ADMIN_VIEW
    ]
  },
  {
    id: 'transactions',
    Icon: TransactionsIcon,
    label: 'Transactions',
    route: TRANSACTIONS.TRANSACTIONS,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_VIEW]
  },
  {
    id: 'quick-collect',
    Icon: QuickCollectIcon,
    label: 'Quick Collect',
    route: QUICK_COLLECT.CREATE_LINK,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_CREATE]
  },
  {
    id: 'report',
    Icon: ReportsIcon,
    label: 'Reports',
    route: REPORTS,
    permissions: [UserPermissionTypes.PAYMENT_ORDER_GENERATE_GST_REPORT]
  },
  {
    id: 'subscriptions',
    Icon: SubscriptionIcon,
    label: 'Plan & Billing',
    route: SUBSCRIPTIONS.PLAN_DETAILS,
    permissions: [UserPermissionTypes.SUBSCRIPTION_VIEW]
  },
  // Todo: Update the permissions for the below menu item
  {
    id: 'org-settings',
    Icon: OrgSettingsIcon,
    label: 'Configurations',
    route: ORG_SETTINGS,
    permissions: [UserPermissionTypes.SUBSCRIPTION_VIEW]
  }
];

const ADMIN_MENU_ITEMS = [
  {
    id: 'kyc-documents',
    Icon: AdminIcon,
    label: 'KYC Documents',
    route: ADMIN.KYC_DOCUMENTS
  },
  {
    id: 'customers-list',
    Icon: CustomerListIcon,
    label: 'Customers List',
    route: ADMIN.CUSTOMERS_LIST,
    permissions: [UserPermissionTypes.CUSTOMER_LIST_VIEW]
  }
];

const HELP_ITEM = {
  id: 'help',
  Icon: HelpIcon,
  label: 'Help',
  route: HELP
};

export { INDIVIDUAL_MENU_ITEMS, ORG_MENU_ITEMS, ADMIN_MENU_ITEMS, HELP_ITEM };
