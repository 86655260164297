/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

// eslint-disable-next-line import/no-extraneous-dependencies
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

/*
* since in dev mode, React Strict Mode is used, useEffect runs twice, resulting in duplication of data.
  Using Map to retrieve unique items from an array based on the specified key.

* @param {T[]} inputList - The input array of objects from which unique items will be extracted.
* @param {K} key - The name of the object field to be used as key.
* @returns {T[]} - An array of objects containing unique items based on the specified key.
*/

export const getUniqueList = <T, K extends keyof T>(
  inputList: T[],
  key: K
): T[] => {
  const inputListMap = new Map<T[K], T>();
  inputList.forEach((item) => {
    inputListMap.set(item[key], item);
  });
  return Array.from(inputListMap.values());
};

export const isValidPhoneNumber = (number: string, countryId: string) => {
  const parsedNumber = parsePhoneNumberFromString(
    `${number}`,
    countryId as CountryCode
  );
  return parsedNumber?.isValid() || false;
};
