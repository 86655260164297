/* eslint-disable @typescript-eslint/naming-convention */
import { defaultCountryCode } from '@components/country-code/constants';
import { formatDate, parseDateString } from '@features/group-management/helper';
import { isValidPhoneNumber } from '@helpers/generic';
import { BloodGroups } from '@types';
import { checkArraysAreSame } from '@utils/generic';
import { getDefaultUserProfileTemplateHeaders } from '@utils/member';
import { snakeToCamel } from '@utils/stringFormat';
import { isInvalidEmail } from '@utils/validations';

import { defaultUserProfileTemplateHeaders } from './constants';
import {
  BranchMember,
  CustomField,
  ICustomFields,
  IMemberFormFieldsState,
  MemberFormFields,
  PossibleBooleanValues,
  UserProfileCustomField,
  UserProfileCustomFieldType,
  UserProfileMemberInput
} from './types';

export function isValidBloodGroup(value: string): boolean {
  return Object.values(BloodGroups).includes(
    value.toUpperCase() as BloodGroups
  );
}

export const isValidInsureValue = (value: string): boolean =>
  Object.values(PossibleBooleanValues)
    .map((v) => v.toLowerCase())
    .includes(value.toLowerCase() as PossibleBooleanValues);

export const getValidationErrorsOnUserProfile = (
  dataHeaders: string[],
  memberDetails: UserProfileMemberInput[],
  customFields: UserProfileCustomField[],
  countryCodeList: string[],
  countryCodeMapping: Record<string, string>
): string[] => {
  // Validate Headers
  const templateHeaders = getDefaultUserProfileTemplateHeaders(
    defaultUserProfileTemplateHeaders,
    customFields
  );

  if (
    checkArraysAreSame(
      dataHeaders.filter((header) => header !== '' && !header.startsWith('_')),
      templateHeaders
    ) === false
  ) {
    return [
      `Column names don't match. Please ensure they follow the latest template.`
    ];
  }

  const errors: string[] = [];
  const primaryNumbers: string[] = [];
  const altNumbers: string[] = [];
  const admissionNumbers: string[] = [];

  memberDetails.forEach((member: UserProfileMemberInput, index: number) => {
    const {
      name,
      primaryNumber,
      altNumber,
      primaryNumberCode,
      altNumberCode,
      email,
      dateOfBirth,
      admissionDate,
      admissionNumber,
      guardianName,
      bloodGroup,
      isInsured
    } = member;

    // Validate name
    if (!name || name.length < 3) errors.push(`Row ${index + 1}: Invalid name`);

    // Validate guardian name
    if (guardianName && guardianName.length < 3)
      errors.push(`Row ${index + 1}: Invalid Guardian Name`);

    // Validate primary number code
    if (
      !primaryNumberCode ||
      (primaryNumberCode && !countryCodeList.includes(`${primaryNumberCode}`))
    ) {
      errors.push(`Row ${index + 1}: Invalid Primary Number Country Code`);
    }

    const primaryNumberWithCode = `+${primaryNumberCode}${primaryNumber}`;

    //  validate primary number
    if (
      !primaryNumber ||
      (primaryNumber &&
        primaryNumberCode &&
        !isValidPhoneNumber(
          primaryNumber,
          countryCodeMapping[primaryNumberCode]
        ))
    )
      errors.push(`Row ${index + 1}: Invalid Primary number format`);

    // check if primary number and name are duplicate
    if (primaryNumbers.includes(`${name}_${primaryNumberWithCode}`))
      errors.push(`Row ${index + 1}: Duplicate Name and Primary Number`);

    // Validate alt number code
    if (altNumberCode && !countryCodeList.includes(`${altNumberCode}`)) {
      errors.push(`Row ${index + 1}: Invalid Alternative Number Country Code`);
    }

    const altNumberWithCode = `+${altNumberCode}${altNumber}`;
    // validate alternate number
    if (
      altNumber &&
      altNumberCode &&
      !isValidPhoneNumber(altNumber, countryCodeMapping[altNumberCode])
    )
      errors.push(`Row ${index + 1}: Invalid Alternative number format`);

    // check primary and secondary numbers are same
    if (altNumber && primaryNumberWithCode === altNumberWithCode)
      errors.push(
        `Row ${index + 1}: Primary and Alternative Number should not be same`
      );

    // Validate email
    if (email && isInvalidEmail(email))
      errors.push(`Row ${index + 1}: Invalid Email`);

    if (bloodGroup && !isValidBloodGroup(bloodGroup))
      errors.push(`Row ${index + 1}: Invalid blood group format`);

    if (isInsured && !isValidInsureValue(isInsured))
      errors.push(`Row ${index + 1}: Invalid is insured value`);

    if (dateOfBirth) {
      const { date, error } = parseDateString(dateOfBirth);
      if (error) errors.push(`Row ${index + 1}: ${error}`);
      if (date && date.getTime() > new Date().setHours(0, 0, 0, 0))
        errors.push(
          `Row ${index + 1}: Date of birth should not be a future date`
        );
    }

    if (admissionDate) {
      const { error } = parseDateString(admissionDate);
      if (error) errors.push(`Row ${index + 1}: ${error}`);
    }

    if (admissionNumber && admissionNumbers.includes(admissionNumber)) {
      errors.push(`Row ${index + 1}: Duplicate Admission Number`);
    }

    // Add Primary Number and AltNumber to array
    if (primaryNumber) primaryNumbers.push(`${name}_${primaryNumberWithCode}`);
    if (altNumber) altNumbers.push(`${name}_${altNumberWithCode}`);
    if (admissionNumber) admissionNumbers.push(admissionNumber);
  });

  return errors;
};

export const getCreateMemberRequestBody = (
  memberList: UserProfileMemberInput[]
): BranchMember[] => {
  const updatedList = memberList.map((member) => {
    const {
      name,
      primaryNumber,
      primaryNumberCode,
      altNumber,
      altNumberCode,
      email,
      dateOfBirth,
      bloodGroup,
      guardianName,
      admissionNumber,
      admissionDate,
      isInsured
    } = member;

    const customFields = [
      ...(isInsured
        ? [
            {
              key: UserProfileCustomFieldType.IS_INSURED,
              value:
                isInsured.toLowerCase() ===
                PossibleBooleanValues.YES.toLowerCase()
                  ? 'true'
                  : 'false'
            }
          ]
        : []),
      ...(bloodGroup
        ? [
            {
              key: UserProfileCustomFieldType.BLOOD_GROUP,
              value: bloodGroup || null
            }
          ]
        : [])
    ];

    return {
      name: name || null,
      mobile: primaryNumber || null,
      country_code: primaryNumberCode || null,
      alternate_mobile: altNumber || null,
      secondary_country_code: altNumberCode || null,
      email: email || null,
      dob: dateOfBirth || null,
      guardian_name: guardianName || null,
      admission_number: admissionNumber?.toString() || null,
      admission_date: admissionDate || null,
      custom_fields: customFields
    };
  });
  return updatedList;
};

export const handleFalsy = (input: any): string | null => {
  if (!input) return null;
  return input;
};

export const resetInvalidDate = (date: string | null): string => {
  if (!date || (date && new Date(date).getFullYear() === 1)) {
    return '';
  }
  return date;
};

export const getMemberFormFieldsStateFromBody = (
  memberBody: BranchMember,
  orgCustomFields: UserProfileCustomField[]
): IMemberFormFieldsState => {
  const {
    name,
    email,
    mobile,
    country_code,
    alternate_mobile,
    secondary_country_code,
    dob,
    guardian_name,
    admission_number,
    admission_date,
    address_line_1,
    address_line_2,
    city,
    state,
    country,
    pincode,
    custom_fields
  } = memberBody;

  const customFieldsState: Partial<IMemberFormFieldsState> = {};

  orgCustomFields?.forEach((field) => {
    const customField = custom_fields?.find((cf) => cf.key === field.key);
    if (customField) {
      customFieldsState[snakeToCamel(field.key) as ICustomFields] =
        customField.value || '';
    }
  });

  const validAdmissionDate = resetInvalidDate(admission_date);
  const validDob = resetInvalidDate(dob);

  return {
    name: name || '',
    email: email || '',
    primaryNumber: mobile || '',
    primaryCountryCode: country_code || defaultCountryCode,
    alternateNumber: alternate_mobile || '',
    alternateCountryCode: secondary_country_code || defaultCountryCode,
    dateOfBirth: validDob,
    guardianName: guardian_name || '',
    admissionNumber: admission_number || '',
    admissionDate: validAdmissionDate,
    addressLine1: address_line_1 || '',
    addressLine2: address_line_2 || '',
    city: city || '',
    state: state || '',
    country: country || '',
    pincode: pincode || '',
    bloodGroup: '',
    isInsured: '',
    ...customFieldsState
  };
};

export const getCreateMemberBodyWithCustomFields = (
  newMember: IMemberFormFieldsState,
  customFields: UserProfileCustomField[]
) => {
  const {
    name,
    email,
    primaryNumber,
    primaryCountryCode,
    alternateNumber,
    alternateCountryCode,
    dateOfBirth,
    guardianName,
    admissionNumber,
    admissionDate,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    pincode
  } = newMember;

  const customFieldsData =
    customFields?.reduce((acc: CustomField[], field) => {
      const value = newMember[snakeToCamel(field.key) as MemberFormFields];
      acc.push({
        key: field.key,
        value: handleFalsy(value)
      });
      return acc;
    }, []) || [];

  return {
    name: handleFalsy(name),
    email: handleFalsy(email),
    mobile: handleFalsy(primaryNumber),
    country_code: primaryCountryCode || defaultCountryCode,
    alternate_mobile: handleFalsy(alternateNumber),
    secondary_country_code: alternateNumber
      ? alternateCountryCode || defaultCountryCode
      : null,
    dob: handleFalsy(formatDate(dateOfBirth)),
    guardian_name: handleFalsy(guardianName),
    admission_number: admissionNumber ? admissionNumber.toString() : null,
    admission_date: handleFalsy(formatDate(admissionDate)),
    address_line_1: handleFalsy(addressLine1),
    address_line_2: handleFalsy(addressLine2),
    city: handleFalsy(city),
    state: handleFalsy(state),
    country: handleFalsy(country),
    pincode: handleFalsy(pincode),
    custom_fields: customFieldsData
  };
};

export const isMemberFormStateEqual = (
  state1: IMemberFormFieldsState,
  state2: IMemberFormFieldsState
): boolean => {
  const keys = Object.keys(state1) as MemberFormFields[];
  return keys.every((key) => state1[key] === state2[key]);
};

export const getFormattedCountryCode = (
  countryCode?: string | null
): string => {
  if (countryCode) {
    return `+${countryCode}`;
  }
  return '';
};
