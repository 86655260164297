import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DOCFILTERTYPE } from '../constants';

interface OrgInfo {
  name: string;
}

interface AdminState {
  currentOrgInfo: OrgInfo;
  orgSearchParam: string;
  orgSelectedFilterParam: DOCFILTERTYPE | null;
  individualSearchParam: string;
  individualSelectedFilterParam: DOCFILTERTYPE | null;
  selectedAdminTab: 'Organization' | 'Individual';
  selectedCustomerListTab: 'Organization' | 'Individual';
  customerOrgSelectedFilter: string[] | null;
  customerIndividualSelectedFilter: string[] | null;
}

const initialState: AdminState = {
  currentOrgInfo: {
    name: ''
  },
  orgSearchParam: '',
  orgSelectedFilterParam: null,
  individualSearchParam: '',
  individualSelectedFilterParam: null,
  selectedAdminTab: 'Organization',
  selectedCustomerListTab: 'Organization',
  customerOrgSelectedFilter: null,
  customerIndividualSelectedFilter: null
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    updateOrgInfo: (state, action: PayloadAction<OrgInfo>) => {
      state.currentOrgInfo = action.payload;
    },
    updateOrgSearchTerm: (state, action: PayloadAction<string>) => {
      state.orgSearchParam = action.payload;
    },
    updateOrgSelectedFilter: (state, action: PayloadAction<DOCFILTERTYPE>) => {
      state.orgSelectedFilterParam = action.payload;
    },
    updateIndividualSearchTerm: (state, action: PayloadAction<string>) => {
      state.individualSearchParam = action.payload;
    },
    updateIndividualSelectedFilter: (
      state,
      action: PayloadAction<DOCFILTERTYPE>
    ) => {
      state.individualSelectedFilterParam = action.payload;
    },
    updateSelectedAdminTab: (
      state,
      action: PayloadAction<'Organization' | 'Individual'>
    ) => {
      state.selectedAdminTab = action.payload;
    },
    updateSelectedCustomerListTab: (
      state,
      action: PayloadAction<'Organization' | 'Individual'>
    ) => {
      state.selectedCustomerListTab = action.payload;
    },
    updateCustomerOrgSelectedFilter: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.customerOrgSelectedFilter = action.payload;
    },
    updateCustomerIndividualSelectedFilter: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.customerIndividualSelectedFilter = action.payload;
    },
    resetAdminState: () => initialState
  }
});

export const {
  updateOrgInfo,
  updateOrgSearchTerm,
  updateOrgSelectedFilter,
  updateIndividualSearchTerm,
  updateIndividualSelectedFilter,
  updateSelectedAdminTab,
  updateSelectedCustomerListTab,
  updateCustomerIndividualSelectedFilter,
  updateCustomerOrgSelectedFilter,
  resetAdminState
} = adminSlice.actions;

export default adminSlice.reducer;
