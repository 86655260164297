// eslint-disable-next-line import/prefer-default-export
export enum ErrorCode {
  ERR_UNAUTHORIZED_PERMISSION = 'ERR_UNAUTHORIZED_PERMISSION',
  ERR_UNAUTHORIZED = 'ERR_UNAUTHORIZED',
  ERR_USER_ORGANISATION_ROLE_NOT_FOUND = 'ERR_USER_ORGANISATION_ROLE_NOT_FOUND',
  ERR_NOT_WHITELIST_NUMBER = 'ERR_NOT_WHITELIST_NUMBER',
  ERR_SUBSCRIPTION_EXPIRED = 'ERR_SUBSCRIPTION_EXPIRED',
  ERR_ENDPOINT_RATE_LIMIT_REACHED = 'ERR_ENDPOINT_RATE_LIMIT_REACHED',
  ERR_BRANCH_FORBIDDEN = 'ERR_BRANCH_FORBIDDEN',
  ERR_GROUP_FORBIDDEN = 'ERR_GROUP_FORBIDDEN',
  ERR_PERMISSION_FORBIDDEN = 'ERR_PERMISSION_FORBIDDEN',
  ERR_ORGANISATION_FORBIDDEN = 'ERR_ORGANISATION_FORBIDDEN',
  ERR_ORGANISATION_NOT_FOUND = 'ERR_ORGANISATION_NOT_FOUND'
}

export const RoleLevel403ErrorCodes = [
  ErrorCode.ERR_BRANCH_FORBIDDEN,
  ErrorCode.ERR_GROUP_FORBIDDEN,
  ErrorCode.ERR_PERMISSION_FORBIDDEN
];

export const OrgLevel403ErrorCodes = [ErrorCode.ERR_ORGANISATION_FORBIDDEN];

export const OrgLevel500ErrorCodes = [
  ErrorCode.ERR_ORGANISATION_FORBIDDEN,
  ErrorCode.ERR_ORGANISATION_NOT_FOUND
];
